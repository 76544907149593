import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import "../../css/main.css";

export default function Index() {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "ID",
      dataIndex: "eid",
      key: "eid",
    },
    {
      title: "Organizer ID",
      key: "organizer_id",
      render: (_, record) => (
        <Tag color="yellow">
          <a href={"/viewOrganizer/" + _.created_by}>{_.ocode}</a>
        </Tag>
      ),
    },
    {
      title: "Campaign ID",
      key: "campaign_id",
      render: (_, record) => (
        <Tag color="red">
          <a href={"/viewCampaign/" + _.cid}>{_.ccode}</a>
        </Tag>
      ),
    },
    {
      title: "Event ID",
      key: "event_id",
      render: (_, record) => (
        <Tag color="blue">
          <a href={"/viewEvent/" + _.eid}>{_.event_code}</a>
        </Tag>
      ),
    },
    {
      title: "Tickets Sold",
      key: "ticket_sold",
      render: (_, record) => (
        <Tag color="green">
          $
          {_.total === null
            ? " 0"
            : (
                parseFloat(_.total) -
                (parseFloat(_.delivery) + parseFloat(_.service))
              ).toLocaleString()}{" "}
        </Tag>
      ),
    },
    // {
    //   title: "P&S",
    //   key: "p&s",
    //   render: (_, record) => (
    //     <Tag color="purple">
    //       $
    //       {_.delivery === null ? " 0" : parseFloat(_.delivery).toLocaleString()}
    //     </Tag>
    //   ),
    // },
    {
      title: "Service Fee",

      render: (_, record) => (
        <Tag color="red">
          ${_.service === null ? " 0" : parseFloat(_.service).toLocaleString()}
        </Tag>
      ),
    },
    // {
    //   title: "PPF",

    //   key: "ppf",
    //   render: (_, record) => (
    //     <Tag color="orange">
    //       ${_.service === null ? " 0" : parseFloat(_.ppf).toLocaleString()}
    //     </Tag>
    //   ),
    // },
    {
      title: "Grand Amount",

      render: (_, record) => (
        <Tag color="blue">
          ${_.total === null ? "0" : parseFloat(_.total).toLocaleString()}
        </Tag>
      ),
    },
    {
      title: "Action",

      render: (_, record) => (
        <Button type={"primary"} href={"/view_accounting/" + _.eid}>
          View
        </Button>
      ),
    },
    {
      title: "Status",

      render: (_, record) => (
        <>
          {_.status === "Paid" ? (
            <Tag color="green">{_.status}</Tag>
          ) : (
            <Tag color="yellow">{_.status}</Tag>
          )}
        </>
      ),
    },
    {
      title: "Paid By",
      key: "paid_by",
      render: (_, record) => (
        <Tag color="purple">
          {_.status === "Not Paid" ? (
            "N/A"
          ) : (
            <a href={"/viewAdmin/" + _.aid}>{_.acode}</a>
          )}
        </Tag>
      ),
    },
  ]);

  const getUserListing = () => {
    queenApiCalling({ action: "GET_ACCOUNTING_LIST" }).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    getUserListing();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Accounting"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
