import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  const history = useNavigate();
  useEffect(() => {
    localStorage.removeItem("admin_id");
    localStorage.removeItem("adminSession");
    history("/");
  }, []);
  return <div></div>;
}
