import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Breadcrumb, Descriptions, Button } from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { Table } from "react-bootstrap";
import "../../css/main.css";

export default function ViewUser() {
  const [uid, setUid] = useState(window.location.pathname.split("/")[2]);
  const [fullName, setFullName] = useState("Hiii");
  const [userDetails, setuserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getUserDetails = (id) => {
    const params = {
      uid: id,
      action: "GET_USER_DETAILS",
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setuserDetails(res.data);
        setFullName(res.data.fullname);
      }
    });
  };

  const actionUser = (action) => {
    const params = { action: "USER_ACCOUNT_ACTION", type: action, uid: uid };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getUserDetails(uid);
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    getUserDetails(uid);
  }, [uid, fullName]);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="View User"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/user",
                      title: (
                        <>
                          <UserOutlined />
                          <span>User List</span>
                        </>
                      ),
                    },
                    {
                      title: "View User",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "100%" }}>
                <Table striped="columns">
                  <tr className="tr">
                    <td className="td">
                      <strong>User Id</strong>
                    </td>
                    <td className="td"> {`${userDetails.uid}`}</td>
                  </tr>
                  <tr className="tr">
                    <td className="td">
                      <strong>Fullname</strong>
                    </td>
                    <td className="td">
                      {" "}
                      {`${userDetails.firstname} ${userDetails.lastname}`}
                    </td>
                  </tr>
                  <tr className="tr">
                    <td className="td">
                      <strong>Phone Number</strong>
                    </td>
                    <td className="td"> {`${userDetails.phone}`}</td>
                  </tr>
                  <tr className="tr">
                    <td className="td">
                      <strong>Email Address</strong>
                    </td>
                    <td className="td"> {`${userDetails.email}`}</td>
                  </tr>
                  <tr className="tr">
                    <td className="td">
                      <strong>Location</strong>
                    </td>
                    <td className="td">{`${userDetails.location}`}</td>
                  </tr>

                  <tr className="tr">
                    <td className="td">
                      <strong>Country</strong>
                    </td>
                    <td className="td">US</td>
                  </tr>
                  <tr className="tr">
                    <td className="td">
                      <strong>Account Status</strong>
                    </td>
                    <td className="td">
                      {" "}
                      {`${
                        userDetails.account_status === "Active"
                          ? "Approved"
                          : userDetails.account_status === "Not Verified"
                          ? "Pending"
                          : "Suspended"
                      }`}
                    </td>
                  </tr>
                  <tr className="tr">
                    <td className="td">
                      <strong>Action</strong>
                    </td>
                    <td className="td">
                      {userDetails.isdeleted === "1" ? (
                        <Button danger onClick={() => actionUser("Recover")}>
                          Recover Account
                        </Button>
                      ) : userDetails.account_status === "Active" ? (
                        <Button danger onClick={() => actionUser("Suspend")}>
                          Suspend
                        </Button>
                      ) : (
                        <Button
                          type={"primary"}
                          onClick={() => actionUser("Enable")}
                          style={{ background: "green", height: "50px" }}
                        >
                          Approve
                        </Button>
                      )}
                    </td>
                  </tr>
                </Table>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
