import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Breadcrumb, Descriptions, Button, Divider } from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "../../css/main.css";

export default function ViewOrder() {
  const [oid, setoid] = useState(window.location.pathname.split("/")[2]);
  const [fullName, setFullName] = useState("Hiii");
  const [userDetails, setuserDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [orderEmail, setOrderEmail] = useState(null);
  const [orderDetail, setOrderDetail] = useState({ bookingTime: "" });
  const [deliveryAddress, setDeliveryAddress] = useState({
    bookingTime: "",
  });
  const [campaignDetail, setCampaignDetail] = useState({
    cid: "",
    eid: "",
    event_name: "",
    event_id: "",
    event_date: "",
    event_venue: "",
    createdBy: "",
    cname: "",
    ccode: "",
    oname: "",
    ocode: "",
  });
  const [fullname, setFullname] = useState(null);
  const [street_address_one, setStreet_address_one] = useState(null);
  const [street_address_two, setStreet_address_two] = useState(null);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [zipcode, setZipcode] = useState(null);
  const [email_address, setEmail_address] = useState(null);
  const [phone_number, setPhone_number] = useState(null);
  const [uid, setUid] = useState("");
  const [tickData, setTickData] = useState([]);
  const getDeliveryAddress = (oid) => {
    let params = { action: "GET_ORDER_DELIVERY_ADDRESS", oid: oid };
    queenApiCalling(params).then((res) => {
      console.log(res);
      setFullname(res.address.firstname + " " + res.address.lastname);
      setStreet_address_one(res.address.street_address_one);
      setStreet_address_two(res.address.street_address_two);
      setState(res.address.state);
      setCity(res.address.city);
      setZipcode(res.address.zipcode);
      setEmail_address(res.address.email_address);
      setPhone_number(res.address.phone_number);
    });
  };

  const getAdminOrderDetail = () => {
    let params = { action: "GET_ADMIN_ORDER_DETAILS", oid: oid };
    queenApiCalling(params).then((res) => {
      setOrderDetail(res.data);
      setOrderEmail(res.data.user);
      setUid(res.uid);
      let arr = JSON.parse(res.data.seats);

      console.log("data=====>", arr);

      // const result = arr.reduce((acc, cur) => {
      //   cur.seats.forEach((seat) => {
      //     const existingCategory = acc.find(
      //       (item) => item.category === seat.category
      //     );
      //     if (existingCategory) {
      //       existingCategory.seats.push(seat);
      //     } else {
      //       acc.push({ category: seat.category, seats: [seat] });
      //     }
      //   });
      //   return acc;
      // }, []);
      setTickData(arr);

      if (res.data.address === "Yes") {
        getDeliveryAddress(oid);
      } else {
      }
    });
  };

  const getCampaignDetails = () => {
    let params = { action: "GET_ADMIN_CAMPAIGN_DETAILS", oid: oid };
    queenApiCalling(params).then((res) => {
      setCampaignDetail(res);
    });
  };

  const actionUser = (status, id) => {
    let params = {
      action: "UPDATE_SHIPPING_STATUS",
      status: status,
      byid: id,
      oid: oid,
    };
    queenApiCalling(params).then((res) => {
      getAdminOrderDetail();
    });
  };

  useEffect(() => {
    getAdminOrderDetail();
  }, [oid]);

  useEffect(() => {
    getCampaignDetails();
  }, [oid]);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Order Details"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/orders",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/orders",
                      title: (
                        <>
                          <UserOutlined />
                          <span>Order List</span>
                        </>
                      ),
                    },
                    {
                      title: "View Order",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "100%" }}>
                <Descriptions title="Order Detail" layout={"vertical"}>
                  <Descriptions.Item label="Order Date">
                    {moment(orderDetail.bookingTime, "DD/MM/YYYY HH:mm").format(
                      "DD"
                    ) +
                      " " +
                      moment(
                        orderDetail.bookingTime,
                        "DD/MM/YYYY HH:mm"
                      ).format("ddd") +
                      " " +
                      moment(
                        orderDetail.bookingTime,
                        "DD/MM/YYYY HH:mm"
                      ).format("MMM") +
                      " " +
                      moment(
                        orderDetail.bookingTime,
                        "DD/MM/YYYY HH:mm"
                      ).format("YYYY")}
                  </Descriptions.Item>
                  <Descriptions.Item label="Order ID">
                    {`OD${orderDetail.order_id}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ordered By">
                    {`${orderDetail.name}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ordered By ID">
                    {orderEmail === undefined || orderEmail === null ? (
                      "GUEST ORDER"
                    ) : (
                      <>
                        <a href={"/viewUser/" + uid}>{orderEmail}</a>
                      </>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Payment Method">
                    {`${orderDetail.paidBy}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ticket ID">
                    {`OD${orderDetail.order_id}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ticket Price">
                    {`$${orderDetail.amount}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Service Fee">
                    ${`${orderDetail.serviceCharge}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Payment Processing Fee">
                    ${" "}
                    {((parseInt(orderDetail.amount) -
                      parseInt(orderDetail.serviceCharge) -
                      parseInt(orderDetail.deliveryPrice)) *
                      2.9) /
                      100}
                  </Descriptions.Item>
                  <Descriptions.Item label="Print & Ship Service">
                    {orderDetail.deliveryPrice === "0"
                      ? "NA"
                      : `$${orderDetail.deliveryPrice}`}
                  </Descriptions.Item>

                  {/* <Descriptions.Item label="Action">
                    {userDetails.isdeleted === "1" ? (
                      <Button danger onClick={() => actionUser("Recover")}>
                        Recover Account
                      </Button>
                    ) : userDetails.account_status === "Active" ? (
                      <Button danger onClick={() => actionUser("Suspend")}>
                        Suspend
                      </Button>
                    ) : (
                      <Button
                        type={"primary"}
                        onClick={() => actionUser("Enable")}
                      >
                        Enable
                      </Button>
                    )}
                  </Descriptions.Item> */}
                </Descriptions>
                <Divider title={"Tour Information"} type={"horizontal"}>
                  {"Tour Information"}
                </Divider>
                <Descriptions title="Campaign Name" layout={"vertical"}>
                  <Descriptions.Item label="Campaign  ID">
                    <a
                      href={"/viewCampaign/" + `${campaignDetail.cid}`}
                    >{`${campaignDetail.ccode}`}</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Event Name">
                    {`${campaignDetail.event_name}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Event ID">
                    <a
                      href={"/viewEvent/" + campaignDetail.eid}
                    >{`${campaignDetail.event_id}`}</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Event Date">
                    {" "}
                    {moment(
                      campaignDetail.event_date,
                      "DD-MM-YYYY HH:mm"
                    ).format("DD") +
                      " " +
                      moment(
                        campaignDetail.event_date,
                        "DD-MM-YYYY HH:mm"
                      ).format("ddd") +
                      " " +
                      moment(
                        campaignDetail.event_date,
                        "DD-MM-YYYY HH:mm"
                      ).format("MMM") +
                      " " +
                      moment(
                        campaignDetail.event_date,
                        "DD-MM-YYYY HH:mm"
                      ).format("YYYY")}{" "}
                    {`${moment(
                      campaignDetail.event_date,
                      "DD-MM-YYYY HH:mm"
                    ).format("HH:mm")}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Event Venue">
                    {`${campaignDetail.event_venue}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Seats">
                    {orderDetail.ticketType === "General" ? (
                      <>
                        <div>
                          {" "}
                          <p>VIP:- {orderDetail.vip + " "} </p>{" "}
                        </div>
                        <div>
                          <p style={{ marginLeft: 10 }}>
                            {" "}
                            Regular:- {+" " + orderDetail.normal}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <table style={{ width: "100%" }}>
                          <tr
                            style={{
                              width: "100%",
                              height: 40,
                              border: "1px solid #c5c5c5",
                            }}
                          >
                            <td>Category</td>
                            <td>Seats</td>
                            <td>Price</td>
                            <td>Total</td>
                          </tr>
                          {tickData.map((item) => {
                            return (
                              <tr
                                style={{
                                  width: "100%",
                                  height: 40,
                                  border: "1px solid #c5c5c5",
                                  padding: "10px",
                                }}
                              >
                                <td>
                                  <strong> {item.category}</strong>
                                </td>
                                <td>
                                  <strong> {item.seats}</strong>
                                </td>
                                <td>$ {item.price}</td>
                                <td>$ {parseFloat(item.price)}</td>
                              </tr>
                            );
                          })}
                        </table>
                      </>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Ticket Downloaded">
                    Yes
                  </Descriptions.Item>
                  <Descriptions.Item label="Organizer Name">
                    {`${campaignDetail.oname}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Organizer Company Name">
                    {`${campaignDetail.ocompany}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Organizer ID">
                    <a
                      href={"/viewOrganizer/" + userDetails.createdBy}
                    >{`${campaignDetail.event_id}`}</a>
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Action">
                    {userDetails.isdeleted === "1" ? (
                      <Button danger onClick={() => actionUser("Recover")}>
                        Recover Account
                      </Button>
                    ) : userDetails.account_status === "Active" ? (
                      <Button danger onClick={() => actionUser("Suspend")}>
                        Suspend
                      </Button>
                    ) : (
                      <Button
                        type={"primary"}
                        onClick={() => actionUser("Enable")}
                      >
                        Enable
                      </Button>
                    )}
                  </Descriptions.Item> */}
                </Descriptions>
                <Divider title={"Tour Information"} type={"horizontal"}>
                  {"Print & Post Service"}
                </Divider>
                <Descriptions title="Postal Address" layout={"vertical"}>
                  {orderDetail.address === "No" ? null : (
                    <>
                      <Descriptions.Item label="Full Name">
                        {`${fullname}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Order Date">
                        {`${orderDetail.bookingTime}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Street Address">
                        {`${street_address_one}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Street Address 2 (Optional)">
                        {`${street_address_two}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="City / Town">
                        {`${city}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="State">
                        {state}
                      </Descriptions.Item>
                      <Descriptions.Item label="Zip Code">
                        {`${zipcode}`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Shipping Status">
                        {orderDetail.is_posted === "0"
                          ? "Not Shipped"
                          : "Shipped"}
                      </Descriptions.Item>
                      {/* <Descriptions.Item label="Shipped By">
                        {`${userDetails.is_posted}`}
                      </Descriptions.Item> */}
                      <Descriptions.Item label="Action">
                        {orderDetail.is_posted === "0" ? (
                          <Button
                            danger
                            onClick={() =>
                              actionUser("1", localStorage.getItem("admin_id"))
                            }
                          >
                            Not Shipped
                          </Button>
                        ) : (
                          <Button
                            type={"primary"}
                            onClick={() =>
                              actionUser("0", localStorage.getItem("admin_id"))
                            }
                          >
                            Shipped
                          </Button>
                        )}
                      </Descriptions.Item>
                    </>
                  )}

                  <Descriptions.Item
                    label="Action"
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                      <a
                        href={
                          "https://api.ticketqueens.com/" +
                          orderDetail.pdf_ticket
                        }
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    </div>
                    <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                      <a
                        href={
                          "https://api.ticketqueens.com/" +
                          orderDetail.pdf_ticket
                        }
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        Print
                      </a>
                    </div>
                  </Descriptions.Item>

                  {/* <Descriptions.Item label="Action">
                    {userDetails.isdeleted === "1" ? (
                      <Button danger onClick={() => actionUser("Recover")}>
                        Recover Account
                      </Button>
                    ) : userDetails.account_status === "Active" ? (
                      <Button danger onClick={() => actionUser("Suspend")}>
                        Suspend
                      </Button>
                    ) : (
                      <Button
                        type={"primary"}
                        onClick={() => actionUser("Enable")}
                      >
                        Enable
                      </Button>
                    )}
                  </Descriptions.Item> */}
                </Descriptions>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
