import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import {
  Card,
  Breadcrumb,
  Descriptions,
  Button,
  Image,
  Switch,
  DatePicker,
  Checkbox,
} from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "../../css/main.css";
import dayjs from "dayjs";
import moment from "moment";

export default function ViewCampaign() {
  const [cid, setCid] = useState(window.location.pathname.split("/")[2]);
  const [campaignDetail, setCampaignDetail] = useState({
    code: "-",
    name: "-",
    intro: "-",
    category: "-",
    genre: "-",
    video_preview: "-",
    photo: "",
    event: "-",
    organizer_code: "-",
    is_featured: "-",
    featured_expiry: "-",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [expiery, setExpiery] = useState(false);
  const [expieryDate, setExpieryDate] = useState(null);
  const [expieryValue, setExpieryValue] = useState(false);

  const getCampaignDetails = (id) => {
    const params = {
      cid: id,
      action: "GET_CAMPAIGN_DETAILS",
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setCampaignDetail(res);
        if (res.is_featured === "1") {
          setExpieryValue(true);
        }
        console.log(expieryValue, res.is_featured);
      }
    });
  };

  const updateExpiery = () => {
    let params = {
      action: "UPDATE_CAMPAIGN_FEATURED",
      cid: cid,
      date: expieryDate,
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  const updateNotFeatured = () => {
    let params = {
      action: "REMOVE_FEATURED",
      cid: cid,
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    getCampaignDetails(cid);
  }, [cid]);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="View Campaign"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/campaigns",
                      title: (
                        <>
                          <UserOutlined />
                          <span>Campaign List</span>
                        </>
                      ),
                    },
                    {
                      title: "View Campaign",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "100%" }}>
                <Descriptions title="Campaigns Info" layout={"vertical"}>
                  <Descriptions.Item label="Campaign ID">
                    {`${campaignDetail.code}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Campaign Name">
                    {`${campaignDetail.name}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Campaign Intro">
                    {`${campaignDetail.intro}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Campaign Category">
                    {`${campaignDetail.category_name}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Campaign Category Genre">
                    {`${campaignDetail.genre_name}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Campaign Video Preview">
                    {`${campaignDetail.video_preview}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Campaign Campaign  Photo">
                    {campaignDetail.photo.length > 0 ? (
                      <Image
                        src={
                          "https://api.ticketqueens.com/uploads/" +
                          campaignDetail.photo
                        }
                        width={150}
                      />
                    ) : (
                      <></>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Events">
                    <a
                      href={
                        "/eventListing/" + cid + "/" + campaignDetail.created_by
                      }
                    >{`${campaignDetail.event}`}</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Organizer ID">
                    <a
                      href={"/viewOrganizer/" + campaignDetail.created_by}
                    >{`${campaignDetail.organizer_code}`}</a>
                  </Descriptions.Item>
                  <Descriptions.Item label="Featured">
                    <Checkbox
                      checked={expieryValue}
                      onChange={() => {
                        setExpieryValue(!expieryValue);
                        if (expieryValue === true) {
                          updateNotFeatured();
                        }
                      }}
                    >
                      Featured
                    </Checkbox>
                  </Descriptions.Item>
                  {expieryValue ? (
                    <>
                      <Descriptions.Item label={"Featured Expiry Date"}>
                        <DatePicker
                          format={"YYYY-MM-DD"}
                          onChange={(value) => setExpieryDate(value)}
                        />
                        <Button onClick={() => updateExpiery()}>Update</Button>
                      </Descriptions.Item>
                    </>
                  ) : (
                    <></>
                  )}
                  <Descriptions.Item label={"Expiry Date"}>
                    {moment(
                      campaignDetail.featured_expiry,
                      "YYYY-MM-DD HH:mm"
                    ).format("DD") +
                      " " +
                      moment(
                        campaignDetail.featured_expiry,
                        "YYYY-MM-DD HH:mm"
                      ).format("ddd") +
                      " " +
                      moment(
                        campaignDetail.featured_expiry,
                        "YYYY-MM-DD HH:mm"
                      ).format("MMM") +
                      " " +
                      moment(
                        campaignDetail.featured_expiry,
                        "YYYY-MM-DD"
                      ).format("YYYY")}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
