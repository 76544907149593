import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import {
  Card,
  Space,
  Button,
  Tag,
  Descriptions,
  Image,
  Switch,
  DatePicker,
  Checkbox,
} from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import { Container, Row, Col } from "react-bootstrap";
import "../../css/main.css";

export default function ViewAccounting() {
  const [eid, seteid] = useState(window.location.pathname.split("/")[2]);
  const [data, setData] = useState([]);
  const [seats, setSeats] = useState(0);
  const [total, setTotal] = useState(0);
  const [services, setServices] = useState(0);
  const [ppf, setPpf] = useState(0);
  const [balance, setBalance] = useState(0);
  const [status, setStatus] = useState("Not Paid");
  const [delivery, setDelivery] = useState(0);
  const [capacity, setCapacity] = useState(0);
  const [seatingType, setSeatingType] = useState(null);
  const [vip, setVip] = useState(0);
  const [regular, setRegular] = useState(0);
  const [type, setType] = useState("");
  const [vipTotal, setVipTotal] = useState(0);
  const [normalTotal, setNormalTotal] = useState(0);
  const [tickData, setTickData] = useState([]);
  const [organizer, setOrganizer] = useState(0);
  const [col, setCol] = useState([
    {
      title: "Order Date/Time",
      dataIndex: "orderDate",
      key: "orderDate",
    },
    {
      title: "Order ID",
      key: "orderid",
      render: (_, record) => (
        <Tag color="yellow">
          <a href={"/viewEvent/" + eid}>{_.orderid}</a>
        </Tag>
      ),
    },
    {
      title: "Ordered By ID",
      key: "campaign_id",
      render: (_, record) => (
        <Tag color="red">
          <a href={_.ucode === "GUEST" ? "#" : "/viewUser/" + _.uid}>
            {_.ucode}
          </a>
        </Tag>
      ),
    },
    {
      title: "Ticket Sold",
      key: "event_id",
      render: (_, record) => <Tag color="blue">{_.seats}</Tag>,
    },
    {
      title: "P&S",
      key: "delivery",
      render: (_, record) => (
        <Tag color="green">
          $
          {_.delivery === null ? " 0" : parseFloat(_.delivery).toLocaleString()}
        </Tag>
      ),
    },
    {
      title: "Service Fee",
      key: "p&s",
      render: (_, record) => (
        <Tag color="purple">
          $
          {_.delivery === null ? " 0" : parseFloat(_.delivery).toLocaleString()}
        </Tag>
      ),
    },
    {
      title: "PPF",

      render: (_, record) => (
        <Tag color="red">
          ${_.ppf === null ? " 0" : parseFloat(_.ppf).toLocaleString()}
        </Tag>
      ),
    },
    {
      title: "Total Paid",

      key: "total",
      render: (_, record) => (
        <Tag color="purple">
          ${_.total === null ? " 0" : parseFloat(_.total).toLocaleString()}
        </Tag>
      ),
    },
  ]);

  const getUserListing = () => {
    queenApiCalling({ action: "GET_EVENT_TICKETS_LIST", eid: eid }).then(
      (res) => {
        if (Array.isArray(res)) {
          setData(res);
        }
      }
    );
  };

  const getEventPrice = (code) => {
    let params = { action: "GET_GENERAL_ADMISSON_TICKET_CHARGES", eid: eid };
    queenApiCalling(params).then((res) => {
      console.log(res);
      if (res.status === "success") {
        if (res.type === "Paid") {
          setVip(res.vip_price);
          setRegular(res.price);
        }
      }
    });
  };

  const getEventSummery = () => {
    queenApiCalling({ action: "GET_EVENT_SUMMERY", eid: eid }).then((res) => {
      if (res.status === "success") {
        setSeats(res.seats);
        setTotal(res.total);
        setServices(res.service);
        setPpf(res.ppf);
        setBalance(res.balance);
        setDelivery(res.delivery);
        setStatus(res.paidStatus);
        setCapacity(res.event_venue_capacity);
        setSeatingType(res.seating_type);
        setVipTotal(res.vipSold);
        setNormalTotal(res.regularSold);
        setOrganizer(res.organizer);
        if (res.seating_type === "General Admission") {
          getEventPrice(res.eid);
        } else {
          getTicketReserved(eid);
        }
      }
    });
  };

  const getTicketReserved = (item) => {
    let params = { action: "GET_RESERVED_TICKETS", eid: item };
    queenApiCalling(params).then((res) => {
      // console.log(res);
      if (Array.isArray(res)) {
        let arr = res.map((item) => {
          return {
            seats: JSON.parse(item.seats),
          };
        });

        const result = arr.reduce((acc, cur) => {
          cur.seats.forEach((seat) => {
            const existingCategory = acc.find(
              (item) => item.category === seat.category
            );
            if (existingCategory) {
              existingCategory.seats.push(seat);
            } else {
              acc.push({ category: seat.category, seats: [seat] });
            }
          });
          return acc;
        }, []);
        setTickData(result);
        console.log("result=====>", result);
      }
    });
  };

  const updateStatus = (eid, aid, status) => {
    let params = { action: "UPDATE_EVENT_PAID_STATUS", aid, eid, status };
    queenApiCalling(params).then((res) => {
      getEventSummery();
    });
  };

  useEffect(() => {
    getUserListing();
  }, [eid]);

  useEffect(() => {
    getEventSummery();
  }, [eid]);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Accounting"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Descriptions title="Summary" layout={"vertical"}>
                <Descriptions.Item label="Tickets Sold">
                  {seats}
                </Descriptions.Item>
                <Descriptions.Item label="Organizer Tickets">
                  {organizer}
                </Descriptions.Item>
                <Descriptions.Item label="Total Sales">
                  ${parseFloat(total).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Print & Ship (P&S)">
                  ${parseFloat(delivery).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Service Fee (3.9% + $1.5 Fixed)">
                  ${(parseFloat(services) - parseFloat(ppf)).toLocaleString()}
                </Descriptions.Item>
                <Descriptions.Item label="Payment Processing Fee (PPF) (2.9%)">
                  ${parseFloat(ppf).toLocaleString()}
                </Descriptions.Item>

                {/* ************************** */}
                <Descriptions.Item label="Event Venue Capacity">
                  {capacity}
                </Descriptions.Item>
                {seatingType === "Researved" ? null : (
                  <>
                    <Descriptions.Item label="Regular Tickets">
                      ${parseFloat(regular)} * {parseFloat(normalTotal)} = $
                      {(
                        parseInt(regular) * parseInt(normalTotal)
                      ).toLocaleString()}
                    </Descriptions.Item>
                    <Descriptions.Item label="VIP Tickets">
                      ${parseFloat(vip)} * {parseFloat(vipTotal)} = $
                      {(parseInt(vip) * parseInt(vipTotal)).toLocaleString()}
                    </Descriptions.Item>
                  </>
                )}

                {/* ************************** */}
                <Descriptions.Item label="Status">
                  {status === "Paid" ? (
                    <Button
                      type="primary"
                      onClick={() =>
                        updateStatus(
                          eid,
                          localStorage.getItem("admin_id"),
                          "Not Paid"
                        )
                      }
                    >
                      Paid
                    </Button>
                  ) : (
                    <Button
                      danger
                      onClick={() =>
                        updateStatus(
                          eid,
                          localStorage.getItem("admin_id"),
                          "Paid"
                        )
                      }
                    >
                      Not Paid
                    </Button>
                  )}
                </Descriptions.Item>
              </Descriptions>
              {seatingType === "Researved" ? (
                <Container>
                  <Row>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <h3 style={{ fontSize: 16, fontWeight: "bold" }}>
                        Tickets Sold
                      </h3>
                      <table style={{ width: "100%" }}>
                        <tr
                          style={{
                            width: "100%",
                            height: 40,
                            border: "1px solid #c5c5c5",
                          }}
                        >
                          <td>Category</td>
                          <td>Price</td>
                          <td>Total</td>
                        </tr>
                        {tickData.map((item) => {
                          return (
                            <tr
                              style={{
                                width: "100%",
                                height: 40,
                                border: "1px solid #c5c5c5",
                                padding: "10px",
                              }}
                            >
                              <td>
                                <strong>
                                  {" "}
                                  {item.category}({item.seats.length})
                                </strong>
                              </td>
                              <td>$ {item.seats[0].price}</td>
                              <td>
                                ${" "}
                                {parseFloat(item.seats[0].price) *
                                  item.seats.length}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                      <br></br>
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                </Container>
              ) : null}
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
