import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../css/main.css";
import { Button, Form, Input, Select } from "antd";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";

export default function Info(props) {
  const [form] = Form.useForm();
  const [oid, setOid] = useState(null);
  const [genre, setGenre] = useState([]);
  const [states, setStates] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const onFinish = (values) => {
    let params = {
      action: "UPDATE_ORGANIZER_INFO",
      oid: props.uid,
      category: values.category.value,
      address_one: values.address_one,
      address_two: values.address_two,
      city: values.city,
      state: values.state.value,
      zip: values.postal,
    };
    console.log(params);
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setIsDisabled(true);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  const onFinishFailed = (errorInfo) => {};

  const getState = () => {
    let params = { action: "GET_STATES" };
    queenApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setStates(
          res.map((data) => {
            return {
              label: data.name,
              value: data.id,
            };
          })
        );
      }
    });
  };

  const getCategory = () => {
    let params = { action: "GET_ORGANIZER_CATEGORY" };
    queenApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setGenre(
          res.map((data) => {
            return {
              label: data.name,
              value: data.id,
            };
          })
        );
      }
    });
  };

  const getInfoStatus = () => {
    let params = { action: "GET_ORGANIZER_INFO_STATUS", oid: props.uid };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        form.setFieldsValue({
          category: res.category,
          address_one: res.address_one,
          address_two: res.address_two,
          city: res.city,
          state: res.state,
          postal: res.zip,
        });
      } else {
      }
    });
  };

  const getOrganizerSession = () => {
    try {
      const session = localStorage.getItem("organizerSession");
      const userSession = JSON.parse(session);
      setOid(userSession.id);
    } catch (error) {}
  };

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getInfoStatus();
  }, [props.uid]);

  return (
    <Container className={"infoContainer"}>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <Form
            form={form}
            layout="vertical"
            name={"complex-form"}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{ width: "100%" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Category"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Please select category",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                options={genre}
              />
            </Form.Item>
            <Form.Item
              label="Address Line 1"
              name="address_one"
              rules={[
                {
                  required: true,
                  message: "Please enter your address",
                },
              ]}
            >
              <Input placeholder={"Please enter your address"} />
            </Form.Item>
            <Form.Item label="Address Line 2 (Optional)" name="address_two">
              <Input
                placeholder={"Please enter your address line 2 (Optional)"}
              />
            </Form.Item>
            <Form.Item
              label="City / Town"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Please enter city / town",
                },
              ]}
            >
              <Input placeholder={"Please enter city / town"} />
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Please select state",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="State"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                options={states}
              />
            </Form.Item>
            <Form.Item
              label="Postal Code"
              name="postal"
              rules={[
                {
                  required: true,
                  message: "Please enter postal code",
                },
              ]}
            >
              <Input placeholder={"Please enter postal code"} />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size={"large"}
                htmlType="submit"
                className={"loginButton"}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col md={3}></Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}
