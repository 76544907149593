import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Breadcrumb, Descriptions, Button } from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";

import "../../css/main.css";

export default function ViewAdmin() {
  const [aid, setAid] = useState(window.location.pathname.split("/")[2]);
  const [fullName, setFullName] = useState("Hiii");
  const [adminDetails, setadminDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getAdminDetails = (id) => {
    const params = {
      aid: id,
      action: "GET_ADMIN_DETAILS",
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setadminDetails(res.data);
        setFullName(res.data.fullname);
      }
    });
  };

  const actionAdmin = (action) => {
    const params = { action: "ADMIN_ACCOUNT_ACTION", type: action, aid: aid };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getAdminDetails(aid);
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    getAdminDetails(aid);
  }, [aid, fullName]);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="View Admin"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/admin",
                      title: (
                        <>
                          <UserOutlined />
                          <span>Admin List</span>
                        </>
                      ),
                    },
                    {
                      title: "View Admin",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "100%" }}>
                <Descriptions title="Admin Info" layout={"vertical"}>
                  <Descriptions.Item label="Admin ID">
                    {adminDetails.admin_id}
                  </Descriptions.Item>
                  <Descriptions.Item label="Full Name">
                    {adminDetails.fullname}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone Number">
                    {adminDetails.phone}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email Address">
                    {adminDetails.email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Role">
                    {adminDetails.role}
                  </Descriptions.Item>
                  <Descriptions.Item label="Location">
                    {adminDetails.location}
                  </Descriptions.Item>
                  <Descriptions.Item label="Admin since">
                    {adminDetails.created_date}
                  </Descriptions.Item>
                  <Descriptions.Item label="Action">
                    <>
                      {localStorage.getItem("admin_id") === aid ? (
                        <>-</>
                      ) : (
                        <>
                          {adminDetails.status === "Active" ? (
                            <>
                              <Button
                                danger
                                onClick={() => actionAdmin("Block")}
                              >
                                Block
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                                type={"primary"}
                                onClick={() => actionAdmin("Unblock")}
                              >
                                Unblock
                              </Button>
                            </>
                          )}
                        </>
                      )}
                    </>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
