import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "../../css/main.css";

export default function Index() {
  const [cid, setCid] = useState(window.location.pathname.split("/")[2]);
  const [oid, setOid] = useState(window.location.pathname.split("/")[3]);
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Event ID",
      key: "code",
      render: (_, record) => (
        <a href={"/viewEvent/" + _.id}>
          <Tag color={"#0097e6"}>{_.code}</Tag>
        </a>
      ),
    },
    {
      title: "Event Data / Time",
      render: (_, record) => (
        <Tag color={"#0097e6"}>
          {moment(_.event_date, "YYYY-MM-DD HH:mm").format("ddd") +
            ", " +
            moment(_.event_date, "YYYY-MM-DD HH:mm").format("MMM") +
            ", " +
            moment(_.event_date, "YYYY-MM-DD HH:mm").format("DD") +
            ", " +
            moment(_.event_date, "YYYY-MM-DD HH:mm").format("YYYY")}
        </Tag>
      ),
      key: "event_date_time",
    },
    {
      title: "Event Venue",
      key: "event_venue_name",
      dataIndex: "event_venue_name",
    },
    {
      title: "Seating Type",
      key: "seating_type",
      dataIndex: "seating_type",
    },
    {
      title: "Venue Capacity",
      key: "event_venue_capacity",
      dataIndex: "event_venue_capacity",
    },
    {
      title: "Ticket Price",
      key: "ticket_price",
      dataIndex: "ticket_price",
    },

    {
      title: "Ticket Sold",
      dataIndex: "ticket_sold",
      key: "ticket_sold",
    },

    {
      title: "Event Status",
      render: (_, record) => <Tag color={"#273c75"}>{_.event_status}</Tag>,
      key: "event_status",
    },
  ]);

  const getAllCampaign = () => {
    queenApiCalling({ action: "GET_ORGANIZER_EVENT", oid: oid, cid: cid }).then(
      (res) => {
        if (Array.isArray(res)) {
          setData(res);
        }
      }
    );
  };

  useEffect(() => {
    getAllCampaign();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Events Listing"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
