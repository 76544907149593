import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "../../css/main.css";

export default function Free() {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Date",

      render: (_, record) => (
        <Tag color={"purple"}>
          {moment(_.time).format("MM-DD-YYYY")} / {moment(_.time).format("H:s")}
        </Tag>
      ),
    },
    {
      title: "Fullname",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email Address",
      dataIndex: "email_address",
      key: "email_address",
    },
    {
      title: "Organizer ID",
      key: "user_id",
      render: (_, record) => (
        <a href={"/viewOrganizer/" + _.oid}>
          <Tag color={"purple"}>{_.ocode}</Tag>
        </a>
      ),
    },

    {
      title: "Message",
      dataIndex: "message",
      key: "message",
    },
    {
      title: "Read By",
      key: "read_by",
      render: (_, record) => (
        <a href={"/viewAdmin/" + _.aid}>
          <Tag color={"green"}>{_.admin_id}</Tag>
        </a>
      ),
    },

    {
      title: "Action",

      key: "action",
      render: (_, record) =>
        _.seen === "0" ? (
          <Space size="middle">
            <Button
              type={"primary"}
              style={{ background: "#44bd32", color: "#FFFFFF" }}
              onClick={() =>
                seenUserMessage(_.id, localStorage.getItem("admin_id"))
              }
            >
              Read
            </Button>
          </Space>
        ) : null,
    },
  ]);

  const getUserListing = () => {
    queenApiCalling({ action: "GET_ORGANIZER_FREE_CONSULTATION" }).then(
      (res) => {
        console.log(res);
        if (Array.isArray(res)) {
          setData(res);
        }
      }
    );
  };

  const seenUserMessage = (id, aid) => {
    queenApiCalling({
      action: "SEEN_ORGANIZER_FREE_CONSULTATION",
      id: id,
      aid: aid,
    }).then((res) => {
      getUserListing();
    });
  };

  useEffect(() => {
    getUserListing();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Free Consultations"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
