import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "../../css/main.css";

export default function Index() {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "#",
      key: "id",
      dataIndex: "id",
    },

    {
      title: "Order Date",
      key: "order_date",
      render: (_, record) =>
        moment(_.orderDate, "DD/MM/YYYY HH:mm").format("ddd") +
        ", " +
        moment(_.orderDate, "DD/MM/YYYY HH:mm").format("MMM") +
        ", " +
        moment(_.orderDate, "DD/MM/YYYY HH:mm").format("DD") +
        ", " +
        moment(_.orderDate, "DD/MM/YYYY HH:mm").format("YYYY") +
        ", " +
        moment(_.orderDate, "DD/MM/YYYY HH:mm").format("HH:mm"),
    },
    {
      title: "Event ID",
      key: "event_code",
      render: (_, record) => (
        <a href={"/viewEvent/" + _.eid}>
          <Tag color="green">{_.ecode}</Tag>
        </a>
      ),
    },
    {
      title: "Order ID",
      key: "order_id",
      render: (_, record) => (
        <a href={"/viewOrder/" + _.order_id}>
          <Tag color="purple">{"OD" + _.order_id}</Tag>
        </a>
      ),
    },
    {
      title: "Ticket Price",
      key: "ticket_price",
      render: (_, record) => (
        <Tag color={"purple"}>
          ${parseFloat(_.amount) - parseFloat(_.serviceCharge)}
        </Tag>
      ),
    },
    {
      title: "Print & Ship",
      key: "print_ship",
      render: (_, record) => <Tag color={"red"}>${_.ppf}</Tag>,
    },
    {
      title: "Service Fee",
      key: "service_fee",
      render: (_, record) => <Tag color={"yellow"}>${_.serviceCharge}</Tag>,
    },
    {
      title: "Total  Price",
      key: "total_price",
      render: (_, record) => <Tag color={"blue"}>${_.amount}</Tag>,
    },

    {
      title: "Ordered By ID",
      key: "ordered_by",
      render: (_, record) => (
        <a href={"/viewUser/" + _.uid}>
          <Tag color={"green"}>{_.$ucode}</Tag>
        </a>
      ),
    },

    {
      title: "Shipping Status",
      key: "shipping_status",
      render: (_, record) =>
        _.ppf === "0" ? (
          "NA"
        ) : (
          <>
            <Space size="middle">
              <Button
                type={"primary"}
                style={{ background: "#44bd32", color: "#FFFFFF" }}
              >
                {_.posted === "0" ? "Not Shipped" : "Shipped"}
              </Button>
            </Space>
          </>
        ),
    },
  ]);

  const getUserListing = () => {
    queenApiCalling({ action: "GET_USERS_ORDERS", type: "All" }).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    getUserListing();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Orders"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
