import React, { useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Breadcrumb, Form, Input, Select, Button } from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import "../../css/main.css";

export default function CreateOrganizerCategory() {
  const formRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (v) => {
    setIsLoading(true);
    const params = {
      category_name: v.category_name,
      action: "CREATE_ORGANIZER_CATEGORY",
    };
    queenApiCalling(params).then((res) => {
      setIsLoading(false);
      if (res.status === "success") {
        formRef.current?.resetFields();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Create Organizer Category"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/organizer_categories",
                      title: (
                        <>
                          <UserOutlined />
                          <span>Organizer Category List</span>
                        </>
                      ),
                    },
                    {
                      title: "Create Organizer Category",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "50%" }}>
                <Form
                  ref={formRef}
                  name="control-ref"
                  layout="vertical"
                  name={"complex-form"}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{ width: "100%" }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    name="category_name"
                    label="Category Name"
                    rules={[
                      { required: true, message: "Please enter category name" },
                    ]}
                  >
                    <Input placeholder={"Enter category name"} />
                  </Form.Item>

                  <Form.Item>
                    {isLoading ? (
                      <SyncLoader
                        color={"008080"}
                        loading={isLoading}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ background: "#008080", width: "100%" }}
                      >
                        Create Organizer Category
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
