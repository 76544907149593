import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../css/main.css";
import { Button, Form, Input, Select, Typography } from "antd";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";

export default function Payments(props) {
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(true);
  const [accountType, setAccountType] = useState([
    {
      label: "Checking Accounts",
      value: "Checking Accounts",
    },
    {
      label: "Savings Account",
      value: "Savings Account",
    },
  ]);
  const [oid, setOid] = useState(null);
  const onFinish = (values) => {
    let params = {
      action: "UPDATE_ORGANIZER_PAYMENT_INFO",
      oid: props.uid,
      account_type: values.account_type,
      bank_name: values.bank_name,
      account_holder_name: values.account_holder_name,
      account_number: values.account_number,
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setIsDisabled(true);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };
  const onFinishFailed = (errorInfo) => {};

  const getBankStatus = () => {
    let params = { action: "GET_ORGANIZER_BANK_STATUS", oid: props.uid };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setIsDisabled(true);
        form.setFieldsValue({
          account_type: res.account_type,
          bank_name: res.bank_name,
          account_holder_name: res.account_holder_name,
          account_number: res.account_number,
        });
      } else {
        setIsDisabled(false);
      }
    });
  };

  useEffect(() => {
    getBankStatus();
  }, [props.uid]);

  return (
    <Container className={"infoContainer"}>
      <Row>
        <Col md={3}></Col>
        <Col md={6}>
          <div
            style={{
              textAlign: "center",
              marginBottom: "30px",
              marginTop: "30px",
            }}
          >
            <Typography.Title level={5}>
              Provide your bank details where we can pay your ticket sales.
            </Typography.Title>
          </div>
          <Form
            form={form}
            layout="vertical"
            name={"complex-form"}
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{ width: "100%" }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Bank Account Type"
              name="account_type"
              rules={[
                {
                  required: true,
                  message: "Please select bank account type",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Bank Account Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                options={accountType}
              />
            </Form.Item>
            <Form.Item
              label="Bank Name"
              name="bank_name"
              rules={[
                {
                  required: true,
                  message: "Please enter your bank name",
                },
              ]}
            >
              <Input placeholder={"Please enter your bank name"} />
            </Form.Item>

            <Form.Item
              label="Account Holder Name (Company or Personal)"
              name="account_holder_name"
              rules={[
                {
                  required: true,
                  message: "Please account holder name",
                },
              ]}
            >
              <Input placeholder={"Please enter account holder name"} />
            </Form.Item>

            <Form.Item
              label="Account Number"
              name="account_number"
              rules={[
                {
                  required: true,
                  message: "Please enter account number",
                },
              ]}
            >
              <Input placeholder={"Please enter account number"} />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size={"large"}
                htmlType="submit"
                className={"loginButton"}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
        <Col md={3}></Col>
      </Row>
      <ToastContainer />
    </Container>
  );
}
