import { UPLOAD_API_LINK } from "../Connection";
import axios from "axios";

const uploadImage = (params) => {
  let res = axios({
    method: "post",
    url: `${UPLOAD_API_LINK}`,
    data: params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

  return res;
};

export { uploadImage };
