import React from "react";
import { Button } from "@mui/material";
import { SyncLoader } from "react-spinners";
import "../../css/main.css";

export default function index(props) {
  return props.isLoading ? (
    <>
      <SyncLoader
        color={props.backgroundColor}
        loading={props.isLoading}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{ marginTop: props.top }}
        style={{ marginTop: props.top }}
        margin={props.top}
      />
    </>
  ) : (
    <Button
      variant="outlined"
      style={{
        marginTop: props.top,
        width: props.width,
        backgroundColor: props.backgroundColor,
        color: props.labelColor,
      }}
      onClick={() => props.onClick()}
    >
      {props.label}
    </Button>
  );
}
