import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import "../../css/main.css";

export default function Index() {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Admin Code",
      dataIndex: "adminCode",
      key: "adminCode",
    },
    {
      title: "Fullname",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      render: (_, record) => <Tag color={"purple"}>{_.phoneNumber}</Tag>,
    },
    {
      title: "Email Address",
      key: "emailAddress",
      render: (_, record) => <Tag color={"green"}>{_.emailAddress}</Tag>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Action",

      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type={"success"}
            href={"/editAdmin/" + _.id}
            style={{ background: "#fbc531", color: "#FFFFFF" }}
          >
            Edit
          </Button>
          {_.isDeleted === "1" ? (
            <>
              {localStorage.getItem("admin_id") === _.id ? null : (
                <>
                  <Button danger onClick={() => deleteAdmin(_.id)}>
                    Delete
                  </Button>
                </>
              )}
            </>
          ) : (
            <Button type={"primary"} onClick={() => recoverAdmin(_.id)}>
              Recover Admin
            </Button>
          )}
          <Button
            type={"primary"}
            style={{ background: "#44bd32", color: "#FFFFFF" }}
            href={"/viewAdmin/" + _.id}
          >
            View
          </Button>
        </Space>
      ),
    },
  ]);

  const deleteAdmin = (id) => {
    const params = { action: "ADMIN_ACCOUNT_ACTION", type: "Delete", aid: id };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getAdminListing();
      } else {
        toast.error(res.message);
      }
    });
  };

  const recoverAdmin = (id) => {
    const params = { action: "ADMIN_ACCOUNT_ACTION", type: "Recover", aid: id };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getAdminListing();
      } else {
        toast.error(res.message);
      }
    });
  };

  const getAdminListing = () => {
    queenApiCalling({ action: "GET_ADMIN_LIST" }).then((res) => {
      console.log(res);
      setData(res);
    });
  };

  useEffect(() => {
    getAdminListing();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Admin Listing"
              extra={
                <Button
                  href="/createAdmin"
                  style={{ background: "#5f27cd", color: "#FFFFFF" }}
                >
                  Create Admin
                </Button>
              }
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
