import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { queenApiCalling } from "../../constant/API";
import { Tabs } from "antd";
import { AndroidOutlined, AppleOutlined } from "@ant-design/icons";

import InfoTab from "./InfoTab";
import PaymentTab from "./PaymentTab";
import { GrStatusInfo } from "react-icons/gr";
import { MdPayment } from "react-icons/md";
import "../../css/main.css";

export default function OrganizerView() {
  const [uid, setUid] = useState(window.location.pathname.split("/")[2]);
  const [fullName, setFullName] = useState("");
  const [tabs, setTabs] = useState([
    {
      label: (
        <span>
          <GrStatusInfo /> Info
        </span>
      ),
      key: 2,
      children: <InfoTab uid={uid} />,
    },
    {
      label: (
        <span>
          <MdPayment /> Payment
        </span>
      ),
      key: 3,
      children: <PaymentTab uid={uid} />,
    },
  ]);

  const getOrganizerDetails = (id) => {
    const params = {
      oid: id,
      action: "GET_ORGANIZER_DETAILS",
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setFullName(res.data.firstname + " " + res.data.lastname);
      }
    });
  };

  useEffect(() => {
    getOrganizerDetails(uid);
  }, [uid, fullName]);

  return (
    <Box>
      <Grid></Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <div className="organizerTop">
              <p className="organizerLogginName">
                You are logged in as {fullName}
              </p>
              <p className="organizerGoBack">
                <a href={"/viewOrganizer/" + uid}>Go back to my profile</a>
              </p>
            </div>
            <div
              style={{ width: "98%", marginTop: "-600px", marginLeft: "1%" }}
            >
              <Tabs
                defaultActiveKey={1}
                items={tabs}
                centered
                tabBarStyle={{
                  backgroundColor: "#f0f2f5",
                  color: "#333",

                  width: "100%",
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
