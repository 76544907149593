import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import "../../css/main.css";
import TextInput from "../../components/TextInput/index";
import Button from "../../components/Button/index";
import { ToastContainer, toast } from "react-toastify";
import { queenApiCalling } from "../../constant/API/index";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const history = useNavigate();
  const [email, setEmail] = useState(window.location.pathname.split("/")[2]);
  const [otp, setOtp] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const verifyOTP = () => {
    if (email === "" || email === null) {
      toast.error("Please enter email address");
    } else if (otp === "" || otp === null) {
      toast.error("Please enter otp");
    } else {
      setIsLoading(true);
      queenApiCalling({
        email: email,
        otp: otp,
        action: "ADMIN_LOGIN_OTP_VERIFY",
      }).then((res) => {
        setIsLoading(false);
        if (res.status === "success") {
          const session = {
            admin_id: res.id,
            fullname: res.fullname,
            role: res.role,
            isUserLoggedIn: true,
          };
          localStorage.setItem("adminSession", JSON.stringify(session));
          localStorage.setItem("admin_id", res.id);
          history("/dashboard");
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 2, md: 3 }} direction={"row"}>
        <Grid item md={4}></Grid>
        <Grid item md={4} className={"loginHolder"}>
          <Box className={"loginheader"}>
            <Typography variant={"h4"} gutterBottom>
              Ticket Queens
            </Typography>
          </Box>
          <br></br>
          <Box>
            <Typography variant={"h6"} gutterBottom>
              Two-Factor Authentication
            </Typography>
          </Box>
          <Box>
            <TextInput
              type={"otp"}
              width={"100%"}
              top={"20px"}
              value={otp}
              onChange={setOtp}
              label={"Otp"}
            />
          </Box>
          <Box>
            <Button
              label={"Sign In"}
              backgroundColor={"#008080"}
              top={"20px"}
              labelColor={"#FFFFFF"}
              width={"100%"}
              onClick={() => verifyOTP()}
              isLoading={isLoading}
            />
          </Box>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
}
