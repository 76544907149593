import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Breadcrumb, Form, Input, Button } from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { SyncLoader } from "react-spinners";
import "../../css/main.css";

export default function Terms() {
  const formRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(window.location.pathname.split("/")[2]);
  const [content, setContent] = useState("<p></p>");
  const [form] = Form.useForm();

  const onFinish = (v) => {
    setIsLoading(true);
    const params = {
      video_code: v.video_code,
      action: "UPDATE_ORGANIZER_DASHBOARD_VIDEO",
    };
    queenApiCalling(params).then((res) => {
      setIsLoading(false);
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getContentTerms = (type) => {
    let params = { action: "GET_CONTENT", type: type };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setContent(res.content);
      }
    });
  };

  const getTitle = () => {
    if (type === "terms") {
      return "Update Terms and Condition";
    } else if (type === "privacy") {
      return "Update Privacy Policy";
    } else if (type === "faq") {
      return "Update FAQ's";
    } else if (type === "resale") {
      return "Update Resale";
    } else if (type === "pricing") {
      return "Update Pricing";
    } else if (type === "resolution") {
      return "Update Resolution";
    }
  };

  const updateContent = () => {
    let params = { action: "UPDATE_CONTENT", content: content, type: type };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    getContentTerms(type);
  }, [type]);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title={
                type === "terms"
                  ? "Update Terms and Condition"
                  : type === "privacy"
                  ? "Update Privacy Policy"
                  : type === "faq"
                  ? "Update FAQ's"
                  : ""
              }
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },

                    {
                      title: getTitle(),
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "100%" }}>
                <CKEditor
                  editor={ClassicEditor}
                  data={content}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    console.log("Editor is ready to use!", editor);
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setContent(data);
                  }}
                />
                {isLoading ? (
                  <SyncLoader
                    color={"008080"}
                    loading={isLoading}
                    size={15}
                    aria-label="Loading Spinner"
                    data-testid="loader"
                  />
                ) : (
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      background: "#fbc531",
                      width: "100%",
                      marginTop: "50px",
                    }}
                    onClick={() => updateContent()}
                  >
                    {`${getTitle()}`}
                  </Button>
                )}
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
