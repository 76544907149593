import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Breadcrumb, Form, Input, Select, Button } from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import "../../css/main.css";

export default function DashboardVideo() {
  const formRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (v) => {
    setIsLoading(true);
    const params = {
      video_code: v.video_code,
      action: "UPDATE_ORGANIZER_DASHBOARD_VIDEO",
    };
    queenApiCalling(params).then((res) => {
      setIsLoading(false);
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getDashboardVideoCode = () => {
    let params = { action: "ORGANIZER_DASHBOARD_VIDEO" };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        form.setFieldsValue({
          video_code: res.video_code,
        });
      }
    });
  };

  useEffect(() => {
    getDashboardVideoCode();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Update Organizer Dashboard Video"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },

                    {
                      title: "Organizer Dashboard Video",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "50%" }}>
                <Form
                  ref={formRef}
                  form={form}
                  name="control-ref"
                  layout="vertical"
                  name={"complex-form"}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{ width: "100%" }}
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{
                    remember: true,
                  }}
                >
                  <Form.Item
                    name="video_code"
                    label="Youtube Video Code"
                    rules={[
                      {
                        required: true,
                        message: "Please enter youtube video code",
                      },
                    ]}
                  >
                    <Input placeholder={"Code"} />
                  </Form.Item>

                  <Form.Item>
                    {isLoading ? (
                      <SyncLoader
                        color={"008080"}
                        loading={isLoading}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ background: "#fbc531", width: "100%" }}
                      >
                        Update Organizer Dashboard Video
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
