import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/Login/index";
import OTP from "../pages/OTP/index";
import Dashboard from "../pages/Dashboard/index";
import Admin from "../pages/Admin/index";
import CreateAdmin from "../pages/Admin/CreateAdmin";
import EditAdmin from "../pages/Admin/EditAdmin";
import ViewAdmin from "../pages/Admin/ViewAdmin";
import Users from "../pages/Users/index";
import ViewUser from "../pages/Users/ViewUser";
import Organizer from "../pages/Organizer/index";
import ViewOrganizer from "../pages/Organizer/ViewOrganizer";
import EventCategory from "../pages/Event_Category/Index";
import CreateEventCategory from "../pages/Event_Category/CreateEventCategory";
import EditEventCategory from "../pages/Event_Category/EditEventCategory";
import OrganizerCategory from "../pages/Organizer/OrganizerCategory";
import CreateOrganizerCategory from "../pages/Organizer/CreateOrganizerCategory";
import EditOrganizerCategory from "../pages/Organizer/EditOrganizerCategory";
import DashboardVideo from "../pages/Organizer/DashboardVideo";
import Terms from "../pages/Contents/Terms";
import CategoryGenres from "../pages/Event_Category/CategoryGenres";
import CreateCategoryGenres from "../pages/Event_Category/CreateCategoryGenres";
import EditGenres from "../pages/Event_Category/EditGenres";
import ServiceCharge from "../pages/ServiceFee/ServiceCharge";
import Resale from "../pages/ServiceFee/Resale";
import Campaign from "../pages/Campaign";
import Event from "../pages/Event";
import ViewCampaign from "../pages/Campaign/viewCampaign";
import Banner from "../pages/Banner/index";
import CreateBanner from "../pages/Banner/CreateBanner";
import OrganizerMessage from "../pages/Organizer/message";
import UserMessage from "../pages/Users/message";
import Wallet from "../pages/Users/wallet";
import WalletHistory from "../pages/Users/walletHistory";
import Orders from "../pages/Orders/index";
import GuestOrder from "../pages/Orders/guestOrder";
import ViewOrder from "../pages/Orders/viewOrder";
import Accounting from "../pages/Accounting/index";
import ViewAccounting from "../pages/Accounting/ViewAccounting";
import ViewEvent from "../pages/Event/ViewEvent";
import OrganizerView from "../pages/Organizer/OrganizerView";
import Logout from "../pages/logout";
import ResaleAccount from "../pages/Accounting/Resale";
import Free from "../pages/Organizer/Free";
import Version from "../pages/Version/index";

export default function index() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/otp/:email" element={<OTP />}></Route>
        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/createAdmin" element={<CreateAdmin />}></Route>
        <Route path="/editAdmin/:id" element={<EditAdmin />}></Route>
        <Route path="/viewAdmin/:id" element={<ViewAdmin />}></Route>
        <Route path="/user" element={<Users />}></Route>
        <Route path="/viewUser/:id" element={<ViewUser />}></Route>
        <Route path="/organizer" element={<Organizer />}></Route>
        <Route path="/viewOrganizer/:id" element={<ViewOrganizer />}></Route>
        <Route path="/event_category" element={<EventCategory />}></Route>
        <Route path="/category_genres" element={<CategoryGenres />}></Route>
        <Route path="/campaigns" element={<Campaign />}></Route>
        <Route path="/eventListing/:id/:oid" element={<Event />}></Route>
        <Route path="/viewCampaign/:id" element={<ViewCampaign />}></Route>
        <Route
          path="/createEventGenres"
          element={<CreateCategoryGenres />}
        ></Route>
        <Route
          path="/createEventCategory"
          element={<CreateEventCategory />}
        ></Route>
        <Route
          path="/editEventCategory/:id/:name"
          element={<EditEventCategory />}
        ></Route>
        <Route
          path="/organizer_categories"
          element={<OrganizerCategory />}
        ></Route>
        <Route
          path="/createOrganizerCategory"
          element={<CreateOrganizerCategory />}
        ></Route>
        <Route
          path="/editOrganizerCategory/:id/:name"
          element={<EditOrganizerCategory />}
        ></Route>
        <Route path="/dashboard_video" element={<DashboardVideo />}></Route>
        <Route path="/content/:type" element={<Terms />}></Route>
        <Route
          path="/editEventGenres/:id/:name/:cid"
          element={<EditGenres />}
        ></Route>
        <Route path="/serviceFee" element={<ServiceCharge />}></Route>
        <Route path="/resale_max_min" element={<Resale />}></Route>
        <Route path="/banner" element={<Banner />}></Route>
        <Route path="/createBanner" element={<CreateBanner />}></Route>
        <Route path="/user_message" element={<UserMessage />}></Route>
        <Route path="/organizer_message" element={<OrganizerMessage />}></Route>
        <Route path="/wallet" element={<Wallet />}></Route>
        <Route
          path="/walletHistory/:id/:name"
          element={<WalletHistory />}
        ></Route>
        <Route path="/orders" element={<Orders />}></Route>
        <Route path="/guestOrder" element={<GuestOrder />}></Route>
        <Route path="/viewOrder/:oid" element={<ViewOrder />}></Route>
        <Route path="/accounting" element={<Accounting />}></Route>
        <Route
          path="/view_accounting/:eid"
          element={<ViewAccounting />}
        ></Route>
        <Route path="/viewEvent/:eid" element={<ViewEvent />}></Route>
        <Route path="/organizerView/:uid" element={<OrganizerView />}></Route>
        <Route path="/resale" element={<ResaleAccount />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route path="/free_consultation" element={<Free />}></Route>
        <Route path="/app_version" element={<Version />}></Route>
      </Routes>
    </Router>
  );
}
