import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag, Image } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import "../../css/main.css";

export default function Index() {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Created Date",
      key: "created_date",
      render: (_, record) => (
        <Tag color={"purple"}>
          {moment(_.created_date, "MM-DD-YYYY HH:mm").format("ddd") +
            ", " +
            moment(_.created_date, "MM-DD-YYYY HH:mm").format("MMM") +
            ", " +
            moment(_.created_date, "MM-DD-YYYY HH:mm").format("DD") +
            ", " +
            moment(_.created_date, "MM-DD-YYYY HH:mm").format("YYYY")}
        </Tag>
      ),
    },
    {
      title: "Expiry Date",
      key: "expiery_date",
      render: (_, record) => (
        <>
          {_.location === "Tower Banner" ? (
            <></>
          ) : (
            <>
              <Tag color={"purple"}>
                {moment(_.expiery_date, "MM-DD-YYYY HH:mm").format("ddd") +
                  ", " +
                  moment(_.expiery_date, "MM-DD-YYYY HH:mm").format("MMM") +
                  ", " +
                  moment(_.expiery_date, "MM-DD-YYYY HH:mm").format("DD") +
                  ", " +
                  moment(_.expiery_date, "MM-DD-YYYY HH:mm").format("YYYY")}
              </Tag>
            </>
          )}
        </>
      ),
    },
    {
      title: "Desktop",
      key: "desktop_view",
      render: (_, record) => (
        <Image
          width={100}
          src={"https://api.ticketqueens.com/uploads/" + _.desktop_view}
        />
      ),
    },
    {
      title: "Mobile",
      key: "location",
      render: (_, record) => (
        <>
          {_.mobile_view === "" ? (
            <p>N/A</p>
          ) : (
            <>
              <Image
                width={100}
                src={"https://api.ticketqueens.com/uploads/" + _.mobile_view}
              />
            </>
          )}
        </>
      ),
    },
    {
      title: "Banner Status",

      key: "status",
      render: (_, record) => (
        <>
          {moment.utc(_.expiery_date).fromNow().split(" ").pop() != "ago" ? (
            <Tag color={"green"}>Active</Tag>
          ) : (
            <Tag color={"red"}>Expired</Tag>
          )}
        </>
      ),
    },
    {
      title: "Added By",
      key: "status",
      render: (_, record) => (
        <Tag color={"green"}>
          <a href={"/viewAdmin/" + _.aid}>{_.acode}</a>
        </Tag>
      ),
    },
    {
      title: "Action",

      key: "action",
      render: (_, record) => (
        <Space size="middle">
          {/* <Button
            type={"success"}
            href={"/editAdmin/" + _.id}
            style={{ background: "#fbc531", color: "#FFFFFF" }}
          >
            Edit
          </Button> */}
          <Button
            danger
            onClick={() => {
              deleteBanner(_.id);
            }}
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ]);

  const getAdminListing = () => {
    queenApiCalling({ action: "GET_BANNERS_LIST_ADMIN" }).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };
  const deleteBanner = (id) => {
    queenApiCalling({ action: "DELETE_BANNER", id: id }).then((res) => {
      getAdminListing();
    });
  };

  useEffect(() => {
    getAdminListing();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Banner Listing"
              extra={
                <Button
                  href="/createBanner"
                  style={{ background: "#5f27cd", color: "#FFFFFF" }}
                >
                  Create Banner
                </Button>
              }
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
