import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import "../../css/main.css";

export default function Wallet() {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "ID",
      key: "id",
      render: (_, record) => <p>#</p>,
    },

    {
      title: "Fullname",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "User ID",
      key: "code",
      render: (_, record) => (
        <a href={"/viewUser/" + _.uid}>
          <Tag color={"green"}>{_.code}</Tag>
        </a>
      ),
    },
    {
      title: "Total Amount in Wallet",
      key: "phone",
      render: (_, record) => <Tag color={"purple"}>{_.amount}</Tag>,
    },
    {
      title: "Action",

      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type={"primary"}
            style={{ background: "#44bd32", color: "#FFFFFF" }}
            href={"/walletHistory/" + _.uid + "/" + _.fullname}
          >
            View History
          </Button>
        </Space>
      ),
    },
  ]);

  const getUserListing = () => {
    queenApiCalling({ action: "GET_USERS_WALLET_AMOUNT" }).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    getUserListing();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Users Wallet"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
