import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Breadcrumb, Form, Input, Select, Button } from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import "../../css/main.css";

export default function CreateAdmin() {
  const formRef = React.useRef(null);
  const [aid, setAid] = useState(window.location.pathname.split("/")[2]);
  const [fullName, setFullName] = useState("Hiii");
  const [adminDetails, setadminDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const [accountType, setAccountType] = useState([
    {
      value: "Super Administrator",
      label: "Super Administrator",
    },
    {
      value: "Administrator",
      label: "Administrator",
    },
    {
      value: "Account Administrator",
      label: "Account Administrator",
    },
  ]);

  const onFinish = (v) => {
    setIsLoading(true);
    let params;
    if (adminDetails.role === "Super Administrator") {
      params = {
        fullname: v.fullname,
        phoneNumber: v.phoneNumber,
        password: v.password,
        location: v.location,

        action: "UPDATE_ADMIN",
        aid: aid,
      };
    } else {
      params = {
        fullname: v.fullname,
        phoneNumber: v.phoneNumber,
        password: v.password,
        location: v.location,

        action: "UPDATE_ADMIN",
        aid: aid,
      };
    }

    queenApiCalling(params).then((res) => {
      setIsLoading(false);
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getAdminDetails = (id) => {
    const params = {
      aid: id,
      action: "GET_ADMIN_DETAILS",
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setadminDetails(res.data);
        setFullName(res.data.fullname);
        form.setFieldsValue({
          fullname: adminDetails.fullname,
          phoneNumber: adminDetails.phone,
          password: "",
          location: adminDetails.location,
          role: adminDetails.role,
        });
      }
    });
  };

  useEffect(() => {
    getAdminDetails(aid);
  }, [aid, fullName]);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Edit Admin"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/admin",
                      title: (
                        <>
                          <UserOutlined />
                          <span>Admin List</span>
                        </>
                      ),
                    },
                    {
                      title: "Edit Admin",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "50%" }}>
                <Form
                  ref={formRef}
                  layout="vertical"
                  name={"complex-form"}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{ width: "100%" }}
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{
                    remember: true,
                  }}
                  form={form}
                >
                  <Form.Item
                    name="fullname"
                    label="Fullname"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={"Please enter fullname"}
                      initialValue={fullName}
                    />
                  </Form.Item>
                  <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={"Please enter phone number"} />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[{ required: true }]}
                  >
                    <Input
                      placeholder={"Please enter password"}
                      type={"password"}
                    />
                  </Form.Item>
                  <Form.Item
                    name="location"
                    label="Location"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={"Please enter location"} />
                  </Form.Item>

                  <Form.Item>
                    {isLoading ? (
                      <SyncLoader
                        color={"008080"}
                        loading={isLoading}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ background: "#fbc531", width: "100%" }}
                      >
                        Update Admin
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
