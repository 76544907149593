import "@coreui/coreui/dist/css/coreui.min.css";
import "./App.css";
import Router from "../src/router/index";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <Router></Router>
    </div>
  );
}

export default App;
