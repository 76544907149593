import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import {
  Card,
  Breadcrumb,
  Descriptions,
  Button,
  Image,
  Switch,
  DatePicker,
  Checkbox,
} from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import "../../css/main.css";
import dayjs from "dayjs";

export default function ViewEvent() {
  const [eid, seteid] = useState(window.location.pathname.split("/")[2]);
  const [campaignDetail, setCampaignDetail] = useState({
    code: "-",
    name: "-",
    intro: "-",
    category: "-",
    genre: "-",
    video_preview: "-",
    photo: "",
    event: "-",
    organizer_code: "-",
    is_featured: "-",
    featured_expiry: "-",
  });

  const getEventDetails = (id) => {
    const params = {
      id: id,
      action: "GET_EVENT_DETAIL",
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setCampaignDetail(res);
      }
    });
  };

  useEffect(() => {
    getEventDetails(eid);
  }, [eid]);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="View Event"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Card style={{ width: "100%" }}>
                <Descriptions title="Event Info" layout={"vertical"}>
                  <Descriptions.Item label="Event ID">
                    {`${campaignDetail.event_code}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Event Name">
                    {`${campaignDetail.event_name}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Event Date/Time">
                    {`${campaignDetail.event_date}`}/
                    {`${campaignDetail.event_time}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Event Venue">
                    {`${campaignDetail.event_venue_name}`}
                    {", "}
                    {`${campaignDetail.city}`}
                    {", "}
                    {`${campaignDetail.event_state}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Seating Type">
                    {`${campaignDetail.seating_type}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Venue Capacity">
                    {`${campaignDetail.event_venue_capacity}`}
                  </Descriptions.Item>

                  <Descriptions.Item label="Tickets Price (Regular)">
                    $ {`${campaignDetail.price}`}
                  </Descriptions.Item>

                  <Descriptions.Item label="Tickets Price (VIP)">
                    $ {`${campaignDetail.vip}`}
                  </Descriptions.Item>

                  <Descriptions.Item label="Ticket Sold">
                    {`${campaignDetail.total}`}
                  </Descriptions.Item>

                  <Descriptions.Item label="Event  Status">
                    {`${campaignDetail.event_status}`}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
