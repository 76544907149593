import React, { useState, useEffect } from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavTitle,
  CNavItem,
  CBadge,
  CNavGroup,
  CSidebarToggler,
} from "@coreui/react";
import { Box } from "@mui/material";
import {
  MdSpaceDashboard,
  MdOutlineAdminPanelSettings,
  MdOutlinePrivacyTip,
  MdStarBorderPurple500,
} from "react-icons/md";
import { BiUserCircle, BiCategoryAlt, BiDollarCircle } from "react-icons/bi";
import { RiOrganizationChart, RiYoutubeLine } from "react-icons/ri";
import { GiVerticalBanner } from "react-icons/gi";
import { TbAirConditioning } from "react-icons/tb";
import { FaQuoteLeft, FaSwimmingPool } from "react-icons/fa";
import { BsCalendar2Event } from "react-icons/bs";
import {
  AiFillMessage,
  AiFillWallet,
  AiOutlineDollar,
  AiOutlineLogin,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { queenApiCalling } from "../../constant/API";
import { GoVersions } from "react-icons/go";
export default function Index(props) {
  const history = useNavigate();
  const [menu, setMenu] = useState([]);
  const [name, setName] = useState("");

  const getAdminStatus = () => {
    const session = localStorage.getItem("adminSession");
    if (session != null) {
      const id = localStorage.getItem("admin_id");
      let params = { action: "GET_ADMIN_STATUS", id: id };
      queenApiCalling(params).then((res) => {
        if (res.status === "Active") {
          if (res.is_deleted !== "1") {
            localStorage.removeItem("admin_id");
            localStorage.removeItem("adminSession");
            history("/");
          }
        } else {
          localStorage.removeItem("admin_id");
          localStorage.removeItem("adminSession");
          history("/");
        }
      });
    } else {
      history("/");
    }
  };

  const adminRole = () => {
    try {
      let session = localStorage.getItem("adminSession");
      let type = JSON.parse(session);
      console.log("type=============>", JSON.parse(session).role);
      setName(type.fullname);
      if (
        JSON.parse(session).role === "Super Administrator" ||
        JSON.parse(session).role === "Super Admin"
      ) {
        setMenu([
          {
            id: 1,
            menu: "Dashboard",
            link: "/dashboard",
            icon: (
              <MdSpaceDashboard
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 2,
            menu: "Admin",
            link: "/admin",
            icon: (
              <MdOutlineAdminPanelSettings
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 3,
            menu: "User",
            link: "/user",
            icon: (
              <BiUserCircle
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 4,
            menu: "Organizer",
            link: "/organizer",
            icon: (
              <RiOrganizationChart
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 5,
            menu: "Banner",
            link: "/banner",
            icon: (
              <GiVerticalBanner
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 6,
            menu: "Campaigns",
            link: "/campaigns",
            icon: (
              <BsCalendar2Event
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 7,
            menu: "Event Category",
            link: "/event_category",
            icon: (
              <BiCategoryAlt
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 8,
            menu: "Event Category Genres",
            link: "/category_genres",
            icon: (
              <BiCategoryAlt
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 9,
            menu: "Organizer Categories",
            link: "/organizer_categories",
            icon: (
              <BiCategoryAlt
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 10,
            menu: "Organizer Dashboard Video",
            link: "/dashboard_video",
            icon: (
              <RiYoutubeLine
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 11,
            menu: "Terms & Condition",
            link: "/content/terms",
            icon: (
              <TbAirConditioning
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 12,
            menu: "Privacy Policy",
            link: "/content/privacy",
            icon: (
              <MdOutlinePrivacyTip
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 13,
            menu: "FAQ's",
            link: "/content/faq",
            icon: (
              <FaQuoteLeft
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          // {
          //   id: 17,
          //   menu: "Resale",
          //   link: "/content/resale",
          //   icon: (
          //     <FaQuoteLeft
          //       customClassName="nav-icon"
          //       style={{ marginRight: "10px" }}
          //     />
          //   ),
          // },
          {
            id: 18,
            menu: "Pricing",
            link: "/content/pricing",
            icon: (
              <FaQuoteLeft
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 19,
            menu: "Resolution",
            link: "/content/resolution",
            icon: (
              <FaQuoteLeft
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 20,
            menu: "Orders",
            link: "/orders",
            icon: (
              <MdStarBorderPurple500
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },

          {
            id: 14,
            menu: "Service Fee",
            link: "/serviceFee",
            icon: (
              <BiDollarCircle
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          // {
          //   id: 15,
          //   menu: "Resale Max/Min",
          //   link: "/resale_max_min",
          //   icon: (
          //     <FaSwimmingPool
          //       customClassName="nav-icon"
          //       style={{ marginRight: "10px" }}
          //     />
          //   ),
          // },
          {
            id: 15,
            menu: "Organizer Message",
            link: "/organizer_message",
            icon: (
              <AiFillMessage
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 16,
            menu: "User Message",
            link: "/user_message",
            icon: (
              <AiFillMessage
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 16,
            menu: "Free Consultation",
            link: "/free_consultation",
            icon: (
              <AiFillMessage
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 21,
            menu: "User Wallet",
            link: "/wallet",
            icon: (
              <AiFillWallet
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 17,
            menu: "Accounting",
            link: "/accounting",
            icon: (
              <AiOutlineDollar
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 19,
            menu: "App Version",
            link: "/app_version",
            icon: (
              <GoVersions
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 18,
            menu: "Logout",
            link: "/logout",
            icon: (
              <AiOutlineLogin
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
        ]);
      } else if (JSON.parse(session).role === "Administrator") {
        setMenu([
          {
            id: 1,
            menu: "Dashboard",
            link: "/dashboard",
            icon: (
              <MdSpaceDashboard
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },

          {
            id: 3,
            menu: "User",
            link: "/user",
            icon: (
              <BiUserCircle
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 4,
            menu: "Organizer",
            link: "/organizer",
            icon: (
              <RiOrganizationChart
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 5,
            menu: "Banner",
            link: "/banner",
            icon: (
              <GiVerticalBanner
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 6,
            menu: "Campaigns",
            link: "/campaigns",
            icon: (
              <BsCalendar2Event
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },

          {
            id: 20,
            menu: "Orders",
            link: "/orders",
            icon: (
              <MdStarBorderPurple500
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },

          {
            id: 15,
            menu: "Organizer Message",
            link: "/organizer_message",
            icon: (
              <AiFillMessage
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 16,
            menu: "User Message",
            link: "/user_message",
            icon: (
              <AiFillMessage
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 16,
            menu: "User Wallet",
            link: "/wallet",
            icon: (
              <AiFillWallet
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },

          {
            id: 18,
            menu: "Logout",
            link: "/logout",
            icon: (
              <AiOutlineLogin
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
        ]);
      } else if (JSON.parse(session).role === "Account Administrator") {
        setMenu([
          {
            id: 1,
            menu: "Dashboard",
            link: "/dashboard",
            icon: (
              <MdSpaceDashboard
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },

          {
            id: 16,
            menu: "User Wallet",
            link: "/wallet",
            icon: (
              <AiFillWallet
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          {
            id: 17,
            menu: "Accounting",
            link: "/accounting",
            icon: (
              <AiOutlineDollar
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
          // {
          //   id: 19,
          //   menu: "Resale Accounting",
          //   link: "/resale",
          //   icon: (
          //     <AiOutlineDollar
          //       customClassName="nav-icon"
          //       style={{ marginRight: "10px" }}
          //     />
          //   ),
          // },
          {
            id: 18,
            menu: "Logout",
            link: "/logout",
            icon: (
              <AiOutlineLogin
                customClassName="nav-icon"
                style={{ marginRight: "10px" }}
              />
            ),
          },
        ]);
      }
    } catch (error) {
      alert("error", error);
    }
  };

  const logout = () => {
    localStorage.removeItem("admin_id");
    localStorage.removeItem("adminSession");
    history("/");
  };
  useEffect(() => {
    try {
      adminRole();
    } catch (error) {
      alert(error);
    }
  }, []);

  useEffect(() => {
    getAdminStatus();
  });

  return (
    <Box md={{ width: "100%" }}>
      <CSidebar
        style={{ width: "100%" }}
        className="adminSidebar"
        colorScheme="dark"
      >
        <CSidebarBrand>Hello, {name}</CSidebarBrand>
        {menu.map((item) => {
          return (
            <CSidebarNav
              key={item.id}
              style={{ display: "flex", overflow: "auto" }}
            >
              <CNavItem href={item.link}>
                {item.icon} {item.menu}
              </CNavItem>
            </CSidebarNav>
          );
        })}
      </CSidebar>
    </Box>
  );
}
