import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import {
  Card,
  Breadcrumb,
  Descriptions,
  Button,
  Divider,
  Modal,
  Form,
  Select,
  Input,
} from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import Table from "../../components/Table/Index";
import "../../css/main.css";
import { ModalBody } from "react-bootstrap";

export default function ViewOrganizer() {
  const [form] = Form.useForm();
  const [formBank] = Form.useForm();
  const [uid, setUid] = useState(window.location.pathname.split("/")[2]);
  const [data, setData] = useState([]);
  const [infoOpen, setInfoOpen] = useState(false);
  const [bankOpen, setBankOpen] = useState(false);
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      responsive: ["lg"],
    },
    {
      title: "Crew  ID",
      dataIndex: "code",
      key: "code",
      responsive: ["lg"],
    },
    {
      title: "Crew  Name",
      dataIndex: "fullname",
      key: "fullname",
      responsive: ["lg"],
    },
    {
      title: "Event Assigned",

      key: "event_name",
      responsive: ["lg"],
      render: (_, record) => <a href={"/viewEvent/" + _.eid}>{_.event_name}</a>,
    },
    {
      title: "Event Date/Time",
      dataIndex: "event_time",
      key: "event_time",
      responsive: ["lg"],
    },
    {
      title: "Event Location",
      dataIndex: "event_location",
      key: "event_location",
      responsive: ["lg"],
    },
    {
      title: "Tickets Sold",
      dataIndex: "totalTicket",
      key: "totalTicket",
      responsive: ["lg"],
    },
    {
      title: "This Crew Scanned",
      dataIndex: "totalScannedTicket",
      key: "totalScannedTicket",
      responsive: ["lg"],
    },
  ];
  const [accountType, setAccountType] = useState([
    {
      label: "Checking Accounts",
      value: "Checking Accounts",
    },
    {
      label: "Savings Account",
      value: "Savings Account",
    },
  ]);
  const [fullName, setFullName] = useState("Hiii");
  const [organizerDetails, setorganizerDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [genre, setGenre] = useState([]);
  const [states, setStates] = useState([]);
  const [organizerInfo, setOrganizerInfo] = useState({
    category: "-",
    address_one: "-",
    address_two: "-",
    city: "-",
    state: "-",
    zip: "-",
  });

  const [organizerBank, setOrganizerBank] = useState({
    account_type: "-",
    bank_name: "-",
    account_holder_name: "-",
    account_number: "-",
  });
  const finishBank = (values) => {
    let params = {
      action: "UPDATE_ORGANIZER_PAYMENT_INFO",
      oid: uid,
      account_type: values.account_type,
      bank_name: values.bank_name,
      account_holder_name: values.account_holder_name,
      account_number: values.account_number,
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getOrganizerBankStatus(uid);
      } else {
        toast.error(res.message);
      }
    });
  };
  const onFinishInfo = (values) => {
    let params = {
      action: "UPDATE_ORGANIZER_INFO",
      oid: uid,
      category: values.category,
      address_one: values.address_one,
      address_two: values.address_two,
      city: values.city,
      state: values.state.value,
      zip: values.postal,
    };

    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getOrganizerDetails(uid);
      } else {
        toast.error(res.message);
      }
    });
  };
  const finishBankFailed = () => {};
  const onFinishInfoFailed = (values) => {};
  const getOrganizerDetails = (id) => {
    const params = {
      oid: id,
      action: "GET_ORGANIZER_DETAILS",
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setorganizerDetails(res.data);
        setFullName(res.data.fullname);
      }
    });
  };

  const actionUser = (action) => {
    const params = {
      action: "ORGANIZER_ACCOUNT_ACTION",
      type: action,
      oid: uid,
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        toast.success(res.message);
        getOrganizerDetails(uid);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getOrganizerInfo = (oid) => {
    const params = {
      action: "GET_ORGANIZER_INFO_STATUS",
      oid: oid,
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setOrganizerInfo(res);
        form.setFieldsValue({
          category: res.category.value,
          address_one: res.address_one,
          address_two: res.address_two,
          state: res.state,
          postal: res.zip,

          city: res.city,
        });
      } else {
      }
    });
  };

  const getOrganizerBankStatus = (oid) => {
    const params = {
      action: "GET_ORGANIZER_BANK_STATUS",
      oid: oid,
    };
    queenApiCalling(params).then((res) => {
      if (res.status === "success") {
        setOrganizerBank(res);
        formBank.setFieldsValue({
          account_type: res.account_type,
          bank_name: res.bank_name,
          account_number: res.account_number,
          account_holder_name: res.account_holder_name,
        });
      } else {
      }
    });
  };

  const getEventCrew = () => {
    let params = { action: "GET_CREW_MEMBER", oid: uid };
    queenApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  const getState = () => {
    let params = { action: "GET_STATES" };
    queenApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setStates(
          res.map((data) => {
            return {
              label: data.name,
              value: data.id,
            };
          })
        );
      }
    });
  };

  const getCategory = () => {
    let params = { action: "GET_ORGANIZER_CATEGORY" };
    queenApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setGenre(
          res.map((data) => {
            return {
              label: data.name,
              value: data.id,
            };
          })
        );
      }
    });
  };

  const infoCancel = () => {
    setInfoOpen(false);
  };
  const bankCancel = () => {
    setBankOpen(false);
  };

  useEffect(() => {
    getOrganizerDetails(uid);
  }, [uid, fullName]);

  useEffect(() => {
    getOrganizerInfo(uid);
  }, [uid]);

  useEffect(() => {
    getOrganizerBankStatus(uid);
  }, [uid]);

  useEffect(() => {
    getEventCrew();
  }, [uid]);

  useEffect(() => {
    getState();
  }, []);

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="View Organizer"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/organizer",
                      title: (
                        <>
                          <UserOutlined />
                          <span>Organizer List</span>
                        </>
                      ),
                    },
                    {
                      title: "View Organizer",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "100%" }}>
                <Descriptions title="Organizer Info" layout={"horizontal"}>
                  <Descriptions.Item label="User ID">
                    {`${organizerDetails.code}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Full Name">
                    {`${organizerDetails.firstname} ${organizerDetails.lastname}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Company">
                    {`${organizerDetails.company}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Phone Number">
                    {`${organizerDetails.phone}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email Address">
                    {`${organizerDetails.email}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Campaigns">{0}</Descriptions.Item>
                  <Descriptions.Item label="Events">{0}</Descriptions.Item>
                  <Descriptions.Item label="Location">
                    {`${organizerDetails.location}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Organizer since">
                    {`${organizerDetails.created_time}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Action">
                    {organizerDetails.isdeleted === "1" ? (
                      <Button danger onClick={() => actionUser("Recover")}>
                        Recover Account
                      </Button>
                    ) : organizerDetails.account_status === "Active" ? (
                      <Button danger onClick={() => actionUser("Suspend")}>
                        Suspend
                      </Button>
                    ) : (
                      <Button
                        type={"primary"}
                        onClick={() => actionUser("Enable")}
                        style={{ background: "green" }}
                      >
                        Approve
                      </Button>
                    )}
                    {/* <a
                      href={"/organizerView/" + uid}
                      type={"primary"}
                      className="loginAsOrganizer"
                    >
                      Login As Organizer
                    </a> */}
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Divider>Organizer Information</Divider>
              <Card style={{ width: "100%" }}>
                <Descriptions layout={"horizontal"}>
                  <Descriptions.Item label="Organizer Category">
                    {organizerInfo.category === null ? (
                      ""
                    ) : (
                      <>{`${organizerInfo.category.label}`}</>
                    )}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address I">
                    {`${organizerInfo.address_one}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Address II (Optional)">
                    {`${organizerInfo.address_two}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="City / Town">
                    {`${organizerInfo.city}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="State">
                    {`${organizerInfo.state.label}`},
                    {`${organizerInfo.state.value}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Zip Code">{`${organizerInfo.zip}`}</Descriptions.Item>
                  <Descriptions.Item label="Country">{"US"}</Descriptions.Item>
                  <Descriptions.Item label="">
                    <Button type="primary" onClick={() => setInfoOpen(true)}>
                      Edit
                    </Button>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Divider>Banking & Payments</Divider>
              <Card>
                <Descriptions layout={"horizontal"}>
                  <Descriptions.Item label="Account Type">
                    {`${organizerBank.account_type}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Bank Name">
                    {`${organizerBank.bank_name}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Account Holder's  Name">
                    {`${organizerBank.account_holder_name}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Account Number">
                    {`${organizerBank.account_number}`}
                  </Descriptions.Item>
                  <Descriptions.Item label="">
                    <Button type="primary" onClick={() => setBankOpen(true)}>
                      Edit
                    </Button>
                  </Descriptions.Item>
                </Descriptions>
              </Card>
              <Divider>Event Crew</Divider>
              <Card style={{ width: "100%" }}>
                <Table data={data} col={columns} />
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
      <Modal
        title="Update Info"
        open={infoOpen}
        onCancel={infoCancel}
        okText={""}
        onOk={infoCancel}
      >
        <ModalBody>
          <Form
            form={form}
            layout="vertical"
            name={"complex-form"}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{ width: "100%" }}
            onFinish={onFinishInfo}
            onFinishFailed={onFinishInfoFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Category"
              name="category"
              rules={[
                {
                  required: true,
                  message: "Select category",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Category"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                options={genre}
              />
            </Form.Item>
            <Form.Item
              label="Address Line 1"
              name="address_one"
              rules={[
                {
                  required: true,
                  message: "Enter your address",
                },
              ]}
            >
              <Input placeholder={"Enter your address"} />
            </Form.Item>
            <Form.Item label="Address Line 2 (Optional)" name="address_two">
              <Input placeholder={"Enter your address line 2 (Optional)"} />
            </Form.Item>
            <Form.Item
              label="City / Town"
              name="city"
              rules={[
                {
                  required: true,
                  message: "Enter city / town",
                },
              ]}
            >
              <Input placeholder={"Enter city / town"} />
            </Form.Item>

            <Form.Item
              label="State"
              name="state"
              rules={[
                {
                  required: true,
                  message: "Select state",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="State"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                options={states}
              />
            </Form.Item>
            <Form.Item
              label="Postal Code"
              name="postal"
              rules={[
                {
                  required: true,
                  message: "Enter postal code",
                },
              ]}
            >
              <Input placeholder={"Enter postal code"} />
            </Form.Item>
            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size={"large"}
                htmlType="submit"
                type={"primary"}
                style={{ width: "100%" }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </ModalBody>
      </Modal>
      <Modal title="Update Bank Details" open={bankOpen} onCancel={bankCancel}>
        <ModalBody>
          <Form
            form={formBank}
            layout="vertical"
            name={"complex-form"}
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{ width: "100%" }}
            onFinish={finishBank}
            onFinishFailed={finishBankFailed}
            autoComplete="off"
          >
            <Form.Item
              label="Bank Account Type"
              name="account_type"
              rules={[
                {
                  required: true,
                  message: "Select bank account type",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Bank Account Type"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                style={{ width: "100%" }}
                options={accountType}
              />
            </Form.Item>
            <Form.Item
              label="Bank Name"
              name="bank_name"
              rules={[
                {
                  required: true,
                  message: "Enter your bank name",
                },
              ]}
            >
              <Input placeholder={"Enter your bank name"} />
            </Form.Item>

            <Form.Item
              label="Account Holder Name (Company or Personal)"
              name="account_holder_name"
              rules={[
                {
                  required: true,
                  message: "Account holder name",
                },
              ]}
            >
              <Input placeholder={"Enter account holder name"} />
            </Form.Item>

            <Form.Item
              label="Account Number"
              name="account_number"
              rules={[
                {
                  required: true,
                  message: "Enter account number",
                },
              ]}
            >
              <Input placeholder={"Enter account number"} />
            </Form.Item>

            <Form.Item style={{ textAlign: "center" }}>
              <Button
                size={"large"}
                htmlType="submit"
                type={"primary"}
                style={{ width: "100%" }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </ModalBody>
      </Modal>
    </Box>
  );
}
