import React from "react";
import { TextField } from "@mui/material";

export default function index(props) {
  return (
    <>
      {props.type === "email" ? (
        <TextField
          id={"outlined-basic"}
          label={"Email address"}
          type={"text"}
          variant={"outlined"}
          placeholder={"Please enter email address"}
          style={{ width: props.width, marginTop: props.top }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ) : props.type === "password" ? (
        <TextField
          id={"outlined-basic"}
          label={"Password"}
          variant={"outlined"}
          type={"password"}
          placeholder={"Please enter password"}
          style={{ width: props.width, marginTop: props.top }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ) : props.type === "otp" ? (
        <TextField
          id={"outlined-basic"}
          label={`${props.label}`}
          variant={"outlined"}
          type={"number"}
          placeholder={"Please enter " + `${props.label}`}
          style={{ width: props.width, marginTop: props.top }}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ) : null}
    </>
  );
}
