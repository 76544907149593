import { API_LINK } from "../Connection";

const queenApiCalling = (params) => {
  return fetch(`${API_LINK}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      "Content-type": "application/json; charset=UTF-8",
    },
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: "error",
        message: "API_ERROR " + error,
      };
    });
};

export { queenApiCalling };
