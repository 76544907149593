import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import "../../css/main.css";

export default function Index() {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Organizer ID",
      key: "oid",
      render: (_, record) => <a href={"/viewOrganizer/" + _.id}>{_.oid}</a>,
    },

    {
      title: "Fullname",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Phone Number",
      key: "phone",
      render: (_, record) => <Tag color={"purple"}>{_.phone}</Tag>,
    },
    {
      title: "Email Address",
      key: "email",
      render: (_, record) => <Tag color={"green"}>{_.email}</Tag>,
    },

    {
      title: "Campaigns",
      dataIndex: "campaigns",
      key: "campaigns",
    },

    {
      title: "Events",
      dataIndex: "events",
      key: "events",
    },

    {
      title: "Status",

      key: "action",
      render: (_, record) => (
        <>
          {_.status === "Not Approved" ? (
            <Space size="middle">
              <Button
                type={"primary"}
                style={{ background: "gold", color: "#FFFFFF" }}
              >
                Pending
              </Button>
            </Space>
          ) : _.status === "Disabled" ? (
            <Space size="middle">
              <Button
                type={"primary"}
                style={{ background: "red", color: "#FFFFFF" }}
              >
                Suspended
              </Button>
            </Space>
          ) : _.status === "Approved" ? (
            <Space size="middle">
              <Button
                type={"primary"}
                style={{ background: "#44bd32", color: "#FFFFFF" }}
              >
                Approved
              </Button>
            </Space>
          ) : _.status === "Active" ? (
            <Space size="middle">
              <Button
                type={"primary"}
                style={{ background: "#44bd32", color: "#FFFFFF" }}
              >
                Approved
              </Button>
            </Space>
          ) : _.status === "Not Verified" ? (
            <Space size="middle">
              <Button
                type={"primary"}
                style={{ background: "grey", color: "#FFFFFF" }}
              >
                Pending
              </Button>
            </Space>
          ) : null}
        </>
      ),
    },
  ]);

  const getOrganizerListing = () => {
    queenApiCalling({ action: "GET_ORGANIZER_LIST" }).then((res) => {
      console.log(res);
      setData(res);
    });
  };

  useEffect(() => {
    getOrganizerListing();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Organizer Listing"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
