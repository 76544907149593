import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { queenApiCalling } from "../../constant/API";
import { Container, Row, Col } from "react-bootstrap";

export default function Index() {
  const [data, setData] = useState([]);

  const getAdminNums = () => {
    let params = { action: "GET_ADMIN_NUMS" };
    queenApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    getAdminNums();
  }, []);

  return (
    <Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Row>
          <Col item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Col>

          <Col md={10} fluid>
            <Row>
              {data.map((item) => {
                return (
                  <Col item md={3} style={{ padding: "5px" }}>
                    <div
                      style={{
                        backgroundColor: item.background,
                        width: "100%",
                        minHeight: "150px",
                        borderRadius: "5px",
                        marginTop: "20px",
                      }}
                    >
                      <br></br>
                      <br></br>
                      <div>
                        <Typography color={"#FFFFFF"}>{item.nums}</Typography>
                      </div>
                      <div>
                        <Typography variant={"h6"} color={"#FFFFFF"}>
                          {item.title}
                        </Typography>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Box>
    </Grid>
  );
}
