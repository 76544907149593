import React, { useState, CSSProperties } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import {
  Card,
  Breadcrumb,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
} from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import { FileUploader } from "react-drag-drop-files";
import { uploadImage } from "../../constant/API/UploadImage";
import "../../css/main.css";

export default function CreateBanner() {
  const fileTypes = ["jpg", "png", "gif"];
  const formRef = React.useRef(null);
  const [website, setWebsite] = useState(null);
  const [mobile, setmobile] = useState(null);
  const [date, setDate] = useState("");
  const [show, setShow] = useState(true);
  const [accountType, setAccountType] = useState([
    {
      value: "Homepage Banner",
      label: "Homepage Banner",
    },
    {
      value: "Tower Banner",
      label: "Tower Banner",
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (v) => {
    console.log("value====>", v);

    if (website === null) {
      toast.error("Please select website display image");
    } else {
      setIsLoading(true);
      try {
        const params = {
          locationData: v.location,
          url: v.url,
          expiery_date: date,
          website: website,
          mobile: mobile,
          aid: localStorage.getItem("admin_id"),
          action: "CREATE_BANNER",
        };
        queenApiCalling(params).then((res) => {
          setIsLoading(false);
          if (res.status === "success") {
            formRef.current?.resetFields();
            toast.success(res.message);
          } else {
            toast.error(res.message);
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    console.log("v");
  };

  const uploadImageToServer = (file, type) => {
    setIsLoading(true);
    if (type === "website") {
      let formdata = new FormData();
      formdata.append("image", file);
      formdata.append("action", "UPLOAD_IMAGE");
      uploadImage(formdata).then((res) => {
        //console.log(res);
        setIsLoading(false);
        if (res.data.status === "success") {
          setWebsite(res.data.imageURL);
        }
      });
    } else {
      let formdata = new FormData();
      formdata.append("image", file);
      formdata.append("action", "UPLOAD_IMAGE");
      uploadImage(formdata).then((res) => {
        //console.log(res);
        setIsLoading(false);
        if (res.data.status === "success") {
          setmobile(res.data.imageURL);
        }
      });
    }
  };

  const onChange = (value, dateString) => {
    setDate(dateString);
  };
  const onOk = (value) => {};

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Create Banner"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/banner",
                      title: (
                        <>
                          <UserOutlined />
                          <span>Banner List</span>
                        </>
                      ),
                    },
                    {
                      title: "Create Banner",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "50%" }}>
                <Form
                  ref={formRef}
                  layout="vertical"
                  name={"complex-form"}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{ width: "100%" }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <Form.Item
                    name="location"
                    label="Location"
                    rules={[{ required: true }]}
                  >
                    <Select options={accountType}></Select>
                  </Form.Item>
                  <Form.Item
                    name="url"
                    label="Redierect URL"
                    rules={[{ required: true }]}
                  >
                    <Input placeholder={"Please enter redirect url"} />
                  </Form.Item>
                  {show ? (
                    <Form.Item
                      name="expiery_date"
                      label="Expiry Date"
                      rules={[{ required: true }]}
                      autoComplete={false}
                    >
                      <DatePicker
                        onChange={onChange}
                        style={{ width: "100%" }}
                        format={"MM-DD-YYYY"}
                      />
                    </Form.Item>
                  ) : null}

                  <Form.Item>
                    <FileUploader
                      handleChange={(file) => {
                        uploadImageToServer(file, "website");
                      }}
                      name="file"
                      types={fileTypes}
                      multiple={false}
                      label={"Desktop View Banner (1800px x 400px)"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <FileUploader
                      handleChange={(file) => {
                        uploadImageToServer(file, "mobile");
                      }}
                      name="file"
                      types={fileTypes}
                      multiple={false}
                      label={"Mobile View Banner (750px x 450px)"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>

                  <Form.Item>
                    {isLoading ? (
                      <>
                        <SyncLoader
                          color={"#008080"}
                          size={15}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                          cssOverride={{ marginTop: "20px" }}
                          style={{ marginTop: "20px" }}
                          margin={"20px"}
                        />
                      </>
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ background: "#008080", width: "100%" }}
                      >
                        Create Banner
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <ToastContainer />
    </Box>
  );
}
