import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Breadcrumb, Form, Input, Select, Button } from "antd";
import { queenApiCalling } from "../../constant/API";
import { HomeOutlined, UserOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import "../../css/main.css";

export default function EditGenres() {
  const formRef = React.useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [gid, setGid] = useState(window.location.pathname.split("/")[2]);
  const [cName, setName] = useState(window.location.pathname.split("/")[3]);
  const [cid, setCid] = useState(window.location.pathname.split("/")[4]);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();

  const onFinish = (v) => {
    console.log();
    setIsLoading(true);
    const params = {
      category_id: v.category,
      name: v.genres_name,
      gid: gid,
      action: "UPDATE_EVENT_GENRES",
    };
    queenApiCalling(params).then((res) => {
      setIsLoading(false);
      if (res.status === "success") {
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
    });
  };

  const getEventCategory = () => {
    let params = { action: "GET_EVENT_CATEGORY" };
    queenApiCalling(params).then((res) => {
      if (Array.isArray(res)) {
        setData(
          res.map((data) => {
            return {
              value: data.id,
              label: data.name,
            };
          })
        );
      }
    });
  };

  useEffect(() => {
    getEventCategory();
    form.setFieldsValue({
      genres_name: cName,
      category: cid,
    });
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Create Event Genres"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
              extra={
                <Breadcrumb
                  items={[
                    {
                      href: "/dashboard",
                      title: <HomeOutlined />,
                    },
                    {
                      href: "/category_genres",
                      title: (
                        <>
                          <UserOutlined />
                          <span>Event Genres List</span>
                        </>
                      ),
                    },
                    {
                      title: "Create Event Genres",
                    },
                  ]}
                />
              }
            >
              <Card style={{ width: "50%" }}>
                <Form
                  ref={formRef}
                  name="control-ref"
                  layout="vertical"
                  name={"complex-form"}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{ width: "100%" }}
                  onFinish={onFinish}
                  autoComplete="off"
                  initialValues={{
                    remember: true,
                  }}
                  form={form}
                >
                  <Form.Item
                    name="genres_name"
                    label="Genres Name"
                    rules={[
                      { required: true, message: "Please enter genres name" },
                    ]}
                  >
                    <Input placeholder={"Enter genres name"} />
                  </Form.Item>
                  <Form.Item
                    name="category"
                    label="Select Event Category"
                    rules={[
                      {
                        required: true,
                        message: "Please select event category",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%", textAlign: "left" }}
                      placeholder="Select Event Category"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={data}
                    />
                  </Form.Item>

                  <Form.Item>
                    {isLoading ? (
                      <SyncLoader
                        color={"008080"}
                        loading={isLoading}
                        size={15}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                      />
                    ) : (
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ background: "#fbc531", width: "100%" }}
                      >
                        Update Genre
                      </Button>
                    )}
                  </Form.Item>
                </Form>
              </Card>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
