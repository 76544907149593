import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import "../../css/main.css";
import TextInput from "../../components/TextInput/index";
import Button from "../../components/Button/index";
import { ToastContainer, toast } from "react-toastify";
import { queenApiCalling } from "../../constant/API/index";
import { useNavigate } from "react-router-dom";

export default function Index() {
  const history = useNavigate();
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getSession = () => {
    let session = localStorage.getItem("adminSession");
    if (session != null) {
      history("/dashboard");
    }
  };

  useEffect(() => {
    getSession();
  }, []);

  const loginAdmin = () => {
    if (email === "" || email === null) {
      toast.error("Please enter email address");
    } else if (password === "" || password === null) {
      toast.error("Please enter password");
    } else {
      setIsLoading(true);
      queenApiCalling({
        email: email,
        password: password,
        action: "ADMIN_LOGIN",
      }).then((res) => {
        setIsLoading(false);
        if (res.status === "success") {
          history("/otp/" + email);
        } else {
          toast.error(res.message);
        }
      });
    }
  };

  return (
    <Box>
      <Grid container spacing={{ xs: 2, md: 3 }} direction={"row"}>
        <Grid item md={4}></Grid>
        <Grid item md={4} className={"loginHolder"}>
          <Box className={"loginheader"}>
            <Typography variant={"h4"} gutterBottom>
              TicketQueens
            </Typography>
          </Box>
          <Box>
            <TextInput
              type={"email"}
              width={"100%"}
              top={"20px"}
              value={email}
              onChange={setEmail}
            />
          </Box>
          <Box>
            <TextInput
              type={"password"}
              width={"100%"}
              top={"20px"}
              value={password}
              onChange={setPassword}
            />
          </Box>
          <Box>
            <Button
              label={"Login"}
              backgroundColor={"#008080"}
              top={"20px"}
              labelColor={"#FFFFFF"}
              width={"100%"}
              onClick={() => loginAdmin()}
              isLoading={isLoading}
            />
          </Box>
        </Grid>
        <Grid item md={4}></Grid>
      </Grid>
      <ToastContainer />
    </Box>
  );
}
