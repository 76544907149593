import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import SideBar from "../../components/Sidebar/index";
import { Card, Space, Button, Tag } from "antd";
import Table from "../../components/Table/Index";
import { queenApiCalling } from "../../constant/API";
import { ToastContainer, toast } from "react-toastify";
import "../../css/main.css";

export default function Index() {
  const [data, setData] = useState([]);
  const [col, setCol] = useState([
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Campaign ID",
      dataIndex: "",
      key: "campaign_code",
      render: (_, record) => (
        <a href={"/viewCampaign/" + _.id}>
          <Tag color={"#0097e6"}>{_.campaign_code}</Tag>
        </a>
      ),
    },
    {
      title: "Campaign Name",
      dataIndex: "campaign_name",
      key: "campaign_name",
    },
    {
      title: "Campaign Category",
      key: "event_category",
      render: (_, record) => <Tag color={"purple"}>{_.event_category}</Tag>,
    },
    {
      title: "Campaign Category Genre",
      key: "event_category_genre",
      render: (_, record) => (
        <Tag color={"#fbc531"}>{_.event_category_genre}</Tag>
      ),
    },
    {
      title: "Event",
      key: "event",
      render: (_, record) => (
        <a href={"/eventListing/" + _.id + "/" + _.oid}>
          <Tag color={"#44bd32"}>{_.event}</Tag>
        </a>
      ),
    },
    {
      title: "Featured",
      key: "featured",
      render: (_, record) => (
        <Tag color={"#7f8fa6"}>
          {_.featured === "1" ? _.event_category : "NA"}
        </Tag>
      ),
    },

    {
      title: "Organizer ID",
      render: (_, record) => (
        <a href={"/viewOrganizer/" + _.oid}>
          <Tag color={"#273c75"}>{_.organizer_id}</Tag>
        </a>
      ),
      key: "organizer_id",
    },
  ]);

  const getAllCampaign = () => {
    queenApiCalling({ action: "GET_ALL_CAMPAIGN" }).then((res) => {
      if (Array.isArray(res)) {
        setData(res);
      }
    });
  };

  useEffect(() => {
    getAllCampaign();
  }, []);

  return (
    <Box>
      <Grid>
        <></>
      </Grid>
      <Box md={{ width: "100%" }} style={{ marginTop: "0px" }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 1, md: 1 }}
          sx={{ flexDirection: { xs: "column", md: "row" } }}
        >
          <Grid item md={2}>
            <SideBar type={localStorage.getItem("adminSession").role} />
          </Grid>
          <Grid
            md={10}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 1, md: 1 }}
            sx={{ flexDirection: { xs: "column", md: "row" } }}
            style={{ marginTop: "0px" }}
          >
            <Card
              title="Campaigns Listing"
              style={{
                width: "98%",
                marginLeft: "1%",
                marginTop: "30px",
              }}
            >
              <Table data={data} col={col} />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </Box>
  );
}
